import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { ActionCreators } from '../../redux/actions/index'
import Button from '../../components/UI/Button'
import SocialIcons from '../../components/SocialIcons'
import NewGalleryPopup from './../../components/admin/NewGalleryPopup'
import SimpleReactLightbox, { SRLWrapper } from 'simple-react-lightbox-pro'

let propNames = ["Gallery Title", "Gallery Subtitle"]

class Gallery extends Component<{}> {
  constructor(props){
    super(props)

    this.state = {
      openNewGalleryPopup: false
    }
    this.formData = new FormData()
  }

  async componentDidMount(){
    const okay = await this.props.getGalleries()
    console.log(this.props)
  }

  togglePopup = () => {
    this.setState({openNewGalleryPopup: !this.state.openNewGalleryPopup})
  }

  deleteGallery = async (gallery, index) => {
    this.props.deleteGallery(gallery._id)
  }

  render() {
    return (
      <div className="container" id="releases-page" style={{maxWidth: 900}}>
          <div className="container">
            <div className="row" style={{maxWidth: 1200, margin: 'auto'}}>
              <div className="col-sm-12">
                <h1>Galleries</h1>
                {
                  this.props.redux.secret_galleries &&  Array.isArray(this.props.redux.secret_galleries) && this.props.redux.secret_galleries.map((gallery, index) => 
                    <div key={index} className="secret-gallery-wrap row">
                      
                      <div className="col-sm-3 gallery-meta">
                        <p><b>Title:</b><br/> {gallery.title}</p>
                        <p><b>Subtitle:</b><br/> {gallery.subtitle}</p>
                        <p><b>Unlock:</b> {gallery.unlock_code}</p>
                        <Button onClick={()=>this.deleteGallery(gallery, index)} text="Delete Gallery" />
                      </div>

                      <SimpleReactLightbox>
                        <div className="col-sm-9">
                          <SRLWrapper>
                            {
                              gallery.images.map(image=> <a href={image.metadata.url}><img src={image.metadata.url+'?mode=resize&width=200'} alt={image.metadata.title} /> </a>)
                            }
                          </SRLWrapper>
                        </div>
                      </SimpleReactLightbox>
                    </div>
                  )
                }
              </div>
              <div className="col-sm-12">
                <Button onClick={this.togglePopup} text="New Gallery" />
              </div>
              <NewGalleryPopup openNewGalleryPopup={this.state.openNewGalleryPopup} togglePopup={this.togglePopup}/>
            </div>
          </div>
        </div>
    )
  }
}
function mapStateToProps(redux) {
  return {
    redux
  }
}
function mapDispatchToProps(dispatch){
  return bindActionCreators(ActionCreators, dispatch)
}
export default connect(mapStateToProps, mapDispatchToProps)(Gallery)