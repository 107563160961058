import React, { Component } from 'react'
import { NavLink as Link } from 'react-router-dom'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { ActionCreators } from '../redux/actions/index'
import { server } from '../config'
import Header from '../components/Header'

import '../css/signup.css'
class NotFound extends Component<{}> {
  constructor(props){
    super(props)
  
    this.state = {}
  }

  componentDidMount(){
    let main = document.getElementById('main')
    main.style.minHeight = window.innerHeight + 'px'
    main.style.minWidth = window.innerWidth + 'px'
    window.addEventListener('resize', ()=>{
      main.style.minHeight = window.innerHeight + 'px'
      main.style.minWidth = window.innerWidth + 'px'
    })
  }
  render() {
    return (
      <div className="page container-fluid signup" id="main" style={{
        backgroundSize: 'cover !important',
        background: 'url(images/notfound.jpg) center center no-repeat no-repeat',
        display: 'flex',
        position: 'relative',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center'}}>
        <div style={{background: "rgba(0,0,0,0.4)", position: 'absolute', top: 0, right: 0, left: 0, bottom: 0}} />
        <div style={{textAlign: 'center', width: 768, minHeight: '60vh', position: 'relative', zIndex: 10}}>
        <img style={{maxWidth: 300, position: 'relative', zIndex: 10}} src={`/images/hero-lips.gif`} alt="Not Found"/>
          <h1 style={{fontSize: 48, color: '#fff'}}>404 NOT FOUND</h1>
          <h2 style={{color: '#fff'}}>You've reached a page that does not exist...</h2>
        </div>
      </div>
    )
  }
}

function mapStateToProps(redux) {
  return {
    redux
  }
}

function mapDispatchToProps(dispatch){
  return bindActionCreators(ActionCreators, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(NotFound)