export const reposters = (state = [], action) => {
	switch(action.type) {
		
		case 'GET_REPOSTERS_REJECTED':
				window.alert(action.payload.response.data.error)
				return false
			break;

		case 'GET_REPOSTERS_FULFILLED':
				return action.payload.data;
			break;

		case 'DELETE_REPOSTER_REJECTED':
				window.alert(action.payload.response.data.error)
				return [...state]
			break;

		case 'DELETE_REPOSTER_FULFILLED':
				return [...state, action.payload.data];
			break;

		case 'ADD_REPOSTER_REJECTED':
				window.alert(action.payload.response.data.error)
				return [...state]
			break;

		case 'ADD_REPOSTER_FULFILLED':
			return [...state, action.payload.data];

		case 'UPDATE_REPOSTER_FULFILLED':
			console.log(action.payload.data)
			return action.payload.data

		default:
				return state
	}
}