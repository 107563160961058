import React, { Component } from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom'
import './App.css';
import './css/animations.css';

import { Provider } from 'react-redux'
import Home from './containers/Home'
import Socket from './components/Socket'
import Terminal from './components/Terminal'
import SocialIcons from './components/SocialIcons'
import AudioPlayer from './components/AudioPlayer';
import NotFound from './containers/NotFound'
import './css/frameworks/bootstrap.grid.css'
import AdminController from './containers/AdminController';
import AdminLogin from './containers/AdminLogin';

// Redux Reducers
import store from './redux/store'
import PrivacyPolicy from './containers/PrivacyPolicy';
import Terms from './containers/Terms';
import Gallery from './containers/Gallery';
import LinkTree from './containers/LinkTree';

class App extends Component {
  constructor(props){
    super(props)
  
    this.state = {}
  }
  render() {
    return (
      <Provider store={store}>
        <div style={{height: '100%'}}>
          <Socket />
          <Router>
            <Switch>
              <>
                <Route path="/" component={SocialIcons} />
                <Route exact path="/" component={Home} />
                <Route exact path="/hack" component={Terminal}/>
                <Route path="/admin" component={AdminController}/>
                <Route path="/gallery" component={Gallery}/>
                <Route exact path="/privacy-policy" component={PrivacyPolicy}/>
                <Route exact path="/terms" component={Terms}/>
                <Route exact path="/links" component={LinkTree}/>
              </>
            </Switch>
          </Router>
          {/* <Router>
            <Switch>
              <Route path="*" component={NotFound}/>
            </Switch>
          </Router> */}
          <AudioPlayer />
        </div>
      </Provider>
    );
  }
}

export default App;
