import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { ActionCreators } from '../redux/actions/index'
import TerminalWindow from 'terminal-in-react';
import pseudoFileSystemPlugin from 'terminal-in-react-pseudo-file-system-plugin';
// import { terminalCommands, terminalDescriptions } from '../lib/terminal.js'
import '../css/terminal.css'
import { server, website, production } from '../config'
import { signupForNewsletter } from '../lib/mailchimp';
import { convertFromNumber, convertToNumber } from '../lib/encryptors';
import logger from 'redux-logger';
import Base64 from 'react-native-base64'

// const FileSystemPlugin = pseudoFileSystemPlugin();

class Terminal extends Component<{}> {
  constructor(props){
    super(props)
  
    this.state = {
      isloggedin: false
    }
  }
  
  getQueryParam = (param) => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    return urlParams.get(param) || false
  }

  async componentDidMount(){
    let main = document.getElementById('terminal-wrap')
    main.style.minHeight = window.innerHeight + 'px'
    main.style.minWidth = window.innerWidth + 'px'
    window.addEventListener('resize', ()=>{
      main.style.minHeight = window.innerHeight + 'px'
      main.style.minWidth = window.innerWidth + 'px'
    })
    console.log('Type `user data` in the command line to view your stored information:')

    setTimeout(()=>{
      if(!this.props.redux.user.login){
        window.location.href = '/auth/discord/signin'
      } else {
        let terminalInput = document.querySelector('.terminal-base input')
        terminalInput.setAttribute("autocorrect", "off")
        terminalInput.setAttribute("autocapitalize", "off")
        terminalInput.addEventListener('keypress', function (e) {
          if (e.key === 'Enter') {
            console.log(e.target.value)
          }
        });
        terminalInput.addEventListener('input', (e) => {
          terminalInput.value = terminalInput.value.trimStart()
        })
      }

    this.getDiscordInvite()
    const inviteURL = this.getQueryParam("invite")
    
    if(inviteURL){
      console.log(`-----> ALERT${this.props.redux.user && this.props.redux.user.discord ? ' '+this.props.redux.user.discord.username : ''}: You havent joined the discord yet!  Join now with your invite link: ${inviteURL}`)
      console.log(`-----> ALERT${this.props.redux.user && this.props.redux.user.discord ? ' '+this.props.redux.user.discord.username : ''}: You havent joined the discord yet!  Join now with your invite link: ${inviteURL}`)
      console.log(`-----> ALERT${this.props.redux.user && this.props.redux.user.discord ? ' '+this.props.redux.user.discord.username : ''}: You havent joined the discord yet!  Join now with your invite link: ${inviteURL}`)
    }
    }, 400)
    
  }
  
  getDiscordInvite = async () => {
    const inviteURL = await fetch(server+'/api/discord/invite').then(r=>r.json()).catch(e=>console.log(e))
    console.log('[DISCORD INVITE URL]: '+inviteURL)
  }

  returnUserData = (field, field_2) => {
    let user = this.props.redux.user
    if(field){
      if(user[field]){
        console.log(user[field])
      } else {
        console.log('ERROR: that field doesnt exist')
      }
    } else if (field && field_2) {
      if(user[field][field_2]){
        console.log(user[field][field_2])
      } else {
        console.log('ERROR: that field doesnt exist')
      }
    } else {
      console.log(user)
    }
  }

  confirmedDeleteAllUsersData = async () => {
    this.setState({awaiting_delete_confirmation: null, delete_confirmation: null})
    const result = await fetch('/api/delete-user')
    if(result){
      window.location.href = '/'
    } else {
      alert('There was an error deleting your data, please make sure you are logged in and try again')
    }
  }

  deleteUserData = (field, field_2) => {
    let user = this.props.redux.user
    
    if (field && field_2) {
      
      if(user[field][field_2]){
        console.log(user[field][field_2])
      } else {
        console.log('ERROR: that field doesnt exist')
      }

    } else if(field){

      if(user[field]){
        console.log(user[field])
      } else {
        console.log('ERROR: that field doesnt exist')
      }
    } else {
      this.setState({awaiting_delete_confirmation: true, delete_confirmation: false}, ()=>console.log("Are you sure you want to delete all your data and your user account? You will lose ALL of your unlock codes...  (Y/N)"))
      this.delete_user_timer = setInterval(() => {
        if(this.state.timer){
          this.setState({timer: this.state.timer-1}, ()=>console.log('Awaiting Confirmation: '+this.state.timer))
        } else if (this.state.timer === 0) {
          this.setState({timer: null, awaiting_delete_confirmation: null, delete_confirmation: null}, ()=>clearInterval(this.delete_user_timer))
        } else {
          this.setState({timer: 5}, ()=>console.log('Awaiting Confirmation: '+this.state.timer))
        }
      }, 1000)
    }
  }
  
  openDiscordInvite = async () => {
    const inviteURL = await fetch(server+'/api/discord/invite').then(r=>r.json()).catch(e=>console.log(e))
    window.open(inviteURL, '_blank').focus();
  }
  
  openDiscordChannel = () => {
    document.location.href = 'https://discord.com/channels/707614343188971530/902797853536157716'
  }

  render() {
    return (
      <section id="terminal-wrap" className="row" style={{overflow: 'hidden'}}>
      { this.props.redux.user.login &&
          <TerminalWindow
            color='green'
            backgroundColor='rgb(14, 20, 30)'
            allowTabs={false}
            hideTopBar={true}
            startState={'maximised'}
            barColor='black'
            style={{
              fontWeight: "bold",
              fontSize: "20px",
              maxWidth: '100%',
              height: '100vh'
            }}
            commands={{
              discord:  {
                method: (args, print, runCommand) => {
                  if(this.props.redux.user.joined_discord){
                    this.openDiscordChannel()
                  } else {
                    this.openDiscordInvite()
                  }
                }
              },
              music: (args, print, runCommand) => {
                window.open('https://www.soundcloud.com/ohshutit', '_blank')
              },
              social: (args, print, runCommand) => {
                const key = args[1]
                
                switch (key) {
                  case 'soundcloud':
                    window.open('https://www.soundcloud.com/ohshutit', '_blank')
                    break;
                
                  case 'facebook':
                    window.open('https://www.facebook.com/ohshutit', '_blank')
                    break;
                
                  case 'twitter':
                    window.open('https://www.twitter.com/ohshutit', '_blank')
                    break;
                
                  case 'instagram':
                    window.open('https://www.instagram.com/ohshutit', '_blank')
                    break;
                
                    case 'discord':    
                        if(this.props.redux.user.joined_discord){
                          this.openDiscordChannel()
                        } else {
                          this.openDiscordInvite()
                        }
                      break;
                  
                  default:
                    console.log("Error: you must supply at least one argument in the form of a social media platform name (E.G. soundcloud)")
                    break;
                }
              },
              user: {
                method: (args, print, runCommand) => {
                  if(!args._[0]){
                    return console.log(this.props.redux.user)
                  }
                  switch (args._[0]) {
                    case 'data':
                        this.returnUserData(args._[1], args._[2])
                      break;
                    case 'delete':
                        this.deleteUserData(args._[1], args._[2])
                      break;
                    default:
                      break;
                  }
                }
              },
              eval: {
                method: (args, print, runCommand) => {

                  try{
                    const result = eval(args._.join("").replace(/'"'/g, "'"))
                    console.log(result)
                  } catch(e) {
                    console.log("invalid entry")
                  }

                }
              },
              subscribe: (args, print, runCommand) => {
                const email = args[1]
                console.log('Signing up '+email+' for newsletter')
                signupForNewsletter({email}).then(result => {
                  if(result.code < 300 || result.email){
                    console.log('You successfully signed up')
                    console.log({...result, status: 'confirmed'})
                  } else {
                    console.log("There seems to have been an error, please try again")
                  }
                })
              },
              decode: {
                method: (args, print, runCommand) => {
                  if(args._[1] && typeof args._[1] == 'string') args._[1] = args._[1].toLowerCase();
                  switch ( args._[1] ) {
                    case 'number':
                      console.log(convertFromNumber(args._[0]))
                      break;
                    default:
                      try{
                        let codedMessage = args._[0].trim()
                        if(codedMessage[0] == ' '){
                          codedMessage.spice(0, codedMessage.length+1)
                        }
                        let decoded = Base64.decode(codedMessage).split(",").join(" ")
                        console.log(decoded)
                      } catch(e){
                        console.log('oops, that is not a valid entry')
                      }
                      break;
                  }
                }
              },
              encode: {
                method: (args, print, runCommand) => {
                  if(args._[1]) args._[1] = args._[1].toLowerCase();
                  switch ( args._[1] ) {
                    case 'number':
                      console.log(convertToNumber(args._[0]))
                      break;
                  
                    default:
                      try{
                        console.log(Base64.encode(args._.join(' ')))
                      } catch(e){
                        console.log('oops, that is not a valid entry')
                      }
                      break; 
                  }
                }
              },
              y: (args, print, runCommand) => {
                if(this.state.awaiting_delete_confirmation && !this.state.delete_confirmation){
                  this.setState({delete_confirmation: true}, ()=>{
                    clearTimeout(this.delete_user_timer)
                    this.confirmedDeleteAllUsersData()
                  })
                }
              },
              unlock_code: {
                method: (args, print, runCommand) => {
                  fetch('/api/unlock-code/',{
                    method: 'post',
                    headers: {
                      'Accept': 'application/json',
                      'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(args._)
                  }).then(r=>r.json()).then(result=>{
                    
                    if(result.message){
                      console.log(result.message)
                    }
                    
                    if(result.title){
                      console.log(result.title)
                    }
                    
                    if(result.subtitle){
                      console.log(result.subtitle)
                    }
                    
                    if(result.images){
                      for (let i = 0; i < result.images.length; i++) {
                        const image = result.images[i];
                        console.log(image)
                      }
                    }
                  })
                }
              },
                
            }}
            descriptions={{
              discord: "Go to the discord channel or get invite!",
              show: `show welcome message`,
              social: "type social with a single argument for the platform you'd like to view",
              music: 'Go to soundcloud',
              download: `Use download code.  EG: 'download yourDownloadKey'`,
              user: `Make user commands like 'user data' to update, change, or delete information.  Use 'user delete' to completely delete your account. You can also use this to view your unlock codes`,
              subscribe: `Give the subscibe command an email as a single argument to register for our newsletter`,
              eval: "instructions/rules:\n * type 'eval PASTE_OR_WRITE_CODEHERE' to run js code in the console.  \n * Adding any spaces seperates commands.\n * Do not use padding or spacing in your code.\n * Only one line code is accepted and will only log returned values\n\n",
              n: `"n" is used to cancel a command`,
              y: `"y" is used to confirm a command`,
              unlock_code: 'Enter a numeric unlock code to view hidden content',  
              decode: `Use this to decrpyt base64 messages.  You can alternately use our number decoder for other messages by adding the 'number' argument at the end`,
              encode: `Use this to encrpyt base64 messages.  You can alternately use our number encoder for other messages by adding the 'number' argument at the end`,
            }}
            // plugins={[
            //   FileSystemPlugin
            // ]}
            promptSymbol={`$`}
            msg={`Welcome ${this.props.redux.user && this.props.redux.user.discord ? this.props.redux.user.discord.username : 'user'}.  Type 'help' to view all public commands.  You can also find other commands throughout the site and through email updates.`}
          />
      }
      </section>
    )
  }
}

function mapStateToProps({user, all_songs, eggs}) {
  return {
    redux : {user, all_songs, eggs}
  }
}

function mapDispatchToProps(dispatch){
  return bindActionCreators(ActionCreators, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(Terminal)