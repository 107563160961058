import { server } from "../config"

export const signupForNewsletter = async ({email, first_name, last_name}) => {
  const response = await fetch(`${server}/api/newsletter/signup?email=${email}&first_name=${first_name}&last_name=${last_name}`, { 
          credentials: 'include',
          method: 'POST',
          headers: {
              'user-agent': 'Mozilla/4.0 MDN Example',
              'content-type': 'application/json',
          },
        }).then(r=>r.json()).catch(e=>{return{error: e}})
  return response
}