import React from 'react'
import Popup from '../UI/Popup'
import TextInput from '../UI/TextInput'
import DatePicker from '../UI/DatePicker'
import Switch from '../UI/Switch'
import Button from '../UI/Button'
import FileUpload from '../UI/FileUpload'

export default class NewEggPopup extends React.Component {
  constructor(props){
    super(props)
    this.state = {

    }
    this.formData = new FormData()
  }

  handleFileUpload = (label, file) => {
    this.formData.append(label, file[0] || file)
  }

  saveEgg = async () => {
    this.setState({uploadingRelease: true})
    let propNames = Object.getOwnPropertyNames(this.props)
    propNames.forEach(name => {
      if(name !== 'publishRelease' && name !== 'handleInputchange' && name !== 'togglePopup' && name !== 'uploadEgg')
      this.formData.append([name], this.props[name])
    })
    let response = await this.props.uploadEgg(this.formData)
    console.log({response})
    this.setState({uploadingRelease: false})
    console.log('BEFORE')
    this.props.togglePopup()
    console.log('AFTER')
  }

  showHideField = (field)=>this.setState({[field.target.name]: field.target.checked})

  render(){ 
    return (
      <Popup open={this.props.create_new_release} togglePopup={this.props.togglePopup} closeButtonPosition="right">
        <div className="new-release-inner-wrap" style={{maxWidth: 900, width: '100%'}}>
          <h1 style={{color: '#fff'}}>Schedule a New Egg</h1>
          <div className="row">
            <div className="col-sm-6">
              <TextInput completedColor={'#52c1f7'} icon="fas fa-gem" color="#fff" width={"100%"} name="title" value={this.props.title || ''} placeholder="Egg Title" onChange={this.props.handleInputchange}/>
            </div>
            <div className="col-sm-6">
              <TextInput completedColor={'#52c1f7'} icon="fas fa-paragraph" color="#fff" width={"100%"} name="excerpt" value={this.props.excerpt || ''} placeholder="Egg Excerpt" onChange={this.props.handleInputchange}/>
            </div>
          </div>
          <div className="row">
            <div className="col-sm-6">
              <TextInput completedColor={'#52c1f7'} icon="fas fa-terminal" color="#fff" width={"100%"} name="cli_command" value={this.props.cli_command || ''} placeholder="CLI Command" onChange={this.props.handleInputchange}/>
            </div>
            <div className="col-sm-6">
              <TextInput completedColor={'#52c1f7'} icon="fas fa-smile-wink" color="#fff" width={"100%"} name="hint" value={this.props.hint || ''} placeholder="Command Hint" onChange={this.props.handleInputchange}/>
            </div>
          </div>
        
          {/* FILE UPLOAD */}
          <div className="row">
            <div className="col-sm-12">
              <div className="new-egg-uploads">
                <div className="upload-wrap">
                  <input type="checkbox" name="has_image" id="has_image" onChange={this.showHideField}/> <label style={{color: '#fff', marginRight: 20}} for="has_image"> &nbsp; - Upload Image</label>
                  {
                    this.state.has_image &&
                      <FileUpload
                        type="image"
                        style={{maxWidth: 200, minWidth: 200, height: 200, borderRadius: 0, marginRight: 20}}
                        fileData={this.handleFileUpload}
                        id="image"
                      />
                  }
                </div>
                <div className="upload-wrap">
                  <input type="checkbox" name="has_audio" id="has_audio" onChange={this.showHideField}/> <label style={{color: '#fff', marginRight: 20}} for="has_audio"> &nbsp; - Upload Audio</label>
                  {
                    this.state.has_audio &&
                      <FileUpload
                        type="audio"
                        style={{height: 200, marginRight: 20}}
                        fileData={this.handleFileUpload}
                        id="audio"
                      />
                  }
                </div>
                <div className="upload-wrap">
                  <input type="checkbox" name="has_video" id="has_video" onChange={this.showHideField}/> <label style={{color: '#fff', marginRight: 20}} for="has_video"> &nbsp; - Upload Video</label>
                  {
                    this.state.has_video &&
                    <FileUpload
                    type="video"
                    style={{height: 200}}
                    fileData={this.handleFileUpload}
                    id="video"
                    />
                  }
                </div>
              </div>
            </div>
          </div>

        <div className="row">
          <div className="col-sm-6">
            <DatePicker
              completedColor={'#52c1f7'}
              icon="far fa-calendar-alt"
              color="#fff"
              width={"100%"}
              name="release_date"
              value={this.props.release_date}
              placeholder="Release Date"
              onChange={this.props.handleInputchange}/>
          </div>
          <div className="col-sm-6">
            <TextInput completedColor={'#52c1f7'} icon="fas fa-link" color="#fff" width={"100%"} name="url" value={this.props.url || ''} placeholder="Egg URL" onChange={this.props.handleInputchange}/>
          </div>
        </div>

          <div className="row">
            <div className="col-sm-6">
              <DatePicker
                completedColor={'#52c1f7'}
                icon="far fa-calendar-alt"
                color="#fff"
                width={"100%"}
                name="expire_date"
                value={this.props.expire_date}
                placeholder="Expire Date"
                onChange={this.props.handleInputchange}/>
            </div>
            <div className="col-sm-6">
              <TextInput type="number" completedColor={'#52c1f7'} icon="fas fa-link" color="#fff" width={"100%"} name="max_unlocks" value={this.props.max_unlocks || ''} placeholder="Maximum Unlocks" onChange={this.props.handleInputchange}/>
            </div>
          </div>

          <div className="row">
            <div className="col-sm-12" style={{display: 'flex', justifyContent: "center"}}>
              <Button text="CANCEL" className="fail" style={{marginTop: 20, maxWidth: 200}} onClick={this.props.togglePopup}/>
              <Button loading={this.state.uploadingRelease} text="PUBLISH RELEASE" className="success" style={{marginTop: 20, marginLeft: 15, maxWidth: 200}} onClick={this.saveEgg}/>
            </div>
          </div>
        </div>
      </Popup>
    )
  } 
}
