import React, { Component } from 'react'
import Calendar from 'react-calendar';
import './css/datepicker.css'
import TextInput from './TextInput'

class DatePicker extends Component<{}> {
  constructor(props){
    super(props)
  
    this.state = {
      date: new Date(),
      show_calendar: false
    }
  }
  onChange = (date) => {
    this.setState({date})
    this.props.onChange({prop: this.props.name, value: date})
  }
  componentDidMount(){
    this.props.onChange({prop: this.props.name, value: this.state.date})
  }
  render() {
    return (
      <div onClick={ () => this.setState({show_calendar: true}) }>
        <TextInput
          completedColor={this.props.completedColor}
          disabled={this.props.disabled}
          value={this.state.date.toDateString()}
          icon={this.props.icon}
          style={this.props.style}
          width={this.props.width}
          color={this.props.color}
          placeholder={this.props.placeholder}
          onDeselected={()=>setTimeout(()=>{this.setState({show_calendar: false})}, 100)}
        />
        <div className={this.state.show_calendar ? 'calendar-wrap show-calendar' : 'calendar-wrap hide-calendar'}>
          <Calendar
            onChange={this.onChange}
            value={this.state.date}
          />
        </div>
      </div>
    )
  }
}

export default DatePicker