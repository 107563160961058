import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { ActionCreators } from '../redux/actions/index'
import { onWindowResize } from '../lib'
import logoHeader from '../images/logo-header.gif'
import heroLips from '../images/hero-lips.gif'
class Hero extends Component {
  componentDidMount(){
    onWindowResize(()=>{
      let hero_left = document.getElementById('hero-left')
      let hero_right = document.getElementById('hero-right')
      let the_lips = document.getElementById('the-lips')
      if(window.innerWidth > 768 && hero_left && hero_right && the_lips){
        hero_left.style.minHeight = window.innerHeight + 'px'
        hero_right.style.minHeight = window.innerHeight + 'px'
        the_lips.style.width = (window.innerWidth / 4) + 'px'
      }
    })
  }
  render() {
    return (
      <section className="row">
        <div id="hero-left" className="col-xs-6" style={styles.wrapper}>
          <div id="the-lips" className="tablet-desktop" style={styles.lips}>
            <picture>
              <source srcSet="/images/webp/logo-header.webp" type="image/webp" alt="HU$H lil baby" />
              <source srcSet={logoHeader} type="image/jpeg" alt="HU$H lil baby" /> 
              <img src={logoHeader} style={{width: '100%'}} alt="HU$H lil baby" />
            </picture>
            <picture>
              <source srcSet="/images/webp/hero-lips.webp" type="image/webp" alt="HU$H lil baby" />
              <source srcSet={heroLips} type="image/jpeg" alt="HU$H lil baby" /> 
              <img src={heroLips} style={{width: '100%'}} alt="HU$H lil baby" />
            </picture>
          </div>
        </div>
        <div id="hero-right" className="col-xs-6" style={styles.heroRight}>
            
        </div>
      </section>
    )
  }
}

function mapStateToProps(redux) {
  return {
    redux
  }
}

function mapDispatchToProps(dispatch){
  return bindActionCreators(ActionCreators, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(Hero)



let styles = {
  lips: {
    width: window.innerWidth / 4,
    minWidth: 150,
    position: 'absolute',
    right: 0,
    top: '50%',
    transform: 'translate(50%, -50%)',
    zIndex: '100'
  },
  wrapper: {
    justifyContent: 'center',
    alignItems: 'center',
    display: 'flex',
    background: '#0e141e',
    minHeight: window.innerHeight
  },
  heroRight: {
    background: '#fff',
    minHeight: window.innerHeight
  },
}