import React, { useRef, useEffect, useState } from 'react'
import '../css/sidemenu.css'

export default function SideMenu(props) {
  const {open, children} = props
  const [isInit, setIsInit] = useState(false)
  const animationTime = 330

  const slider = useRef(null);
  
  function OnTransitionEnd() {
    slider.current.classList.remove("menu--animatable");
  } 
  
  useEffect(() => {
    if(slider.current){
      if(!isInit){
        setIsInit(true)
        slider.current.addEventListener("transitionend", OnTransitionEnd, false);
      } 
      
      slider.current.classList.add("menu--animatable");  
      if(open) {       
        slider.current.classList.add("menu--visible");
      } else {
        slider.current.classList.remove('menu--visible');       
      }   
    }
  }, [open])

  return (
    <div className="sidemenu">
      <div className="menu" ref={slider}>
        <div className="app-menu" style={props.style}>
          {children}
        </div>
      </div>
    </div>
  )
}
