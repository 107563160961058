export function convertToNumber(line)
{ 
  let str = "";
  for (var i = 0; i < line.length; i++) {
    str = str + line.charCodeAt(i);
    
    if(i !== line.length - 1){
      str = str + "|";
    }
  }  
  
  return str;
}

export function convertFromNumber(number)
{
  
  if(number === undefined || typeof number !== "string"){
    throw "not a string";
  }
  
  let exp = number.split("|");
  let str = "";
  
  if(exp.length===0){
    throw "no split";
  } else {
    for(let i = 0; i < exp.length; i++){
      let val = exp[i];
      str = str + String.fromCharCode(val);
    }
  }
  
  return str;
}