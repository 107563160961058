import React, { Component } from 'react'
import { NavLink as Link } from 'react-router-dom'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { ActionCreators } from '../redux/actions/index'
import '../css/header.css'
class Header extends Component<{}> {
  render() {
    return (
       <header className="header no-menu">
          <Link to="/">
            <img src="/images/header-logo.png" alt="HU$H Logo" />
          </Link>
       </header>
    )
  }
}

function mapStateToProps(redux) {
  return {
    redux
  }
}

function mapDispatchToProps(dispatch){
  return bindActionCreators(ActionCreators, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(Header)