import React, { Component } from 'react'

class Switch extends Component<{}> {
  constructor(props){
    super(props)
  
    this.state = {}
  }
  onToggle = () => {
    if(this.props.value){
      this.props.onSwitch({prop: this.props.name, value: false})
    } else {
      this.props.onSwitch({prop: this.props.name, value: true})
    }
  }
  render() {
    return (
      <div className="switch-wrap" style={this.props.style}>
        <label className="switch">
          <input type="checkbox" id="discover" checked={this.props.value} onChange={this.onToggle} />
          <span className="slider round" id="discover"></span>
        </label>
        <span style={{marginLeft: 10, color: this.props.color ? this.props.color : '#333'}}> {this.props.text}</span>	
      </div>
    )
  }
}

export default Switch