import createReducer from '../lib/createReducer'
import * as types from '../actions/types'

export const all_songs = createReducer([], {
	[types.LOAD_ALL_SONGS](state, action){
		if(action.add){
			let newState = [...state, action.payload]
			return newState
		} else {
			let newState = action.payload
			return newState
		}
	}
})

export const current_song = createReducer(false,{
	[types.CURRENT_SONG](state, action){
		let newState = action.payload
		return newState
	}
})

export const is_paused = createReducer(true, {
	[types.IS_PAUSED](state, action){
		let newState = action.payload
		return newState
	}
})

export const current_time = createReducer(0, {
	[types.CURRENT_TIME](state, action){
		let newState = action.payload
		return newState
	}
})