import React, { Component } from 'react'
import { BrowserRouter as Router, Route } from 'react-router-dom'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { ActionCreators } from '../redux/actions/index'
import { server, production } from '../config'

class Socket extends Component<{}> {
  
  constructor(props){
    super(props)
  
    this.state = {}
  }
  async componentDidMount(){
    await this.checkIfUserIsLoggedIn()
    
    await this.getSiteSettings()
    // this.loginCheck = setInterval(async () => {
    //   await this.checkIfUserIsLoggedIn
    // }, 1000 * 60 * 15 )
    let songs = await fetch('/api/releases').then(res=>res.json()).catch(e=>console.log({songs: e}))
    window.songs = songs
    this.props.loadAllSongs(songs)
    this.props.getEggs()
  } 
  
  loginCheck = null

  componentWillUnmount(){
    clearInterval(this.loginCheck)
  }
  getSiteSettings = async () => {
    let settings = await fetch('/api/settings').then(r=>r.json()).catch(e => console.log({getSiteSettings_ERROR: e}))
    
    window.website_settings = settings

    if(settings) this.props.siteSettings(settings)
      else alert('there was an error fetching the settings')
  }
  checkIfUserIsLoggedIn = async () => {
    console.log('checking again')
    let response = await fetch('/api/login-check', {
      credentials: 'include'
    }).then(r=>r.json()).catch(e => console.log({checkIfUserIsLoggedIn_ERROR: e}))
    window.myuserdata = response
    if(response){
      let user = response
      let authenticated = response.login
      this.props.userData(user)
      return this.setState({authenticated, user})
    } else {
      return this.props.userData({login: false, unauthorized: true})
    }
  }
  render() {
    return (
        <span>
        {/* <!-- SOCKET COMPONENT --> */}
        </span>
    )
  }
}

function mapStateToProps(redux) {
  return {
    redux
  }
}

function mapDispatchToProps(dispatch){
  return bindActionCreators(ActionCreators, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(Socket)