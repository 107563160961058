import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { ActionCreators } from '../redux/actions/index'
import {all as releases} from '../lib/releases'
import '../css/player.css'

class AudioPlayer extends Component<{}> {
  constructor(props){
    super(props)
  
    this.state = {
      open: false,
    }
    this.time = 0
  }
  componentWillReceiveProps(nuProps){

    // Open Player On Playback
    if(nuProps.redux.current_song){
      
      this.setState({open: true})

      // Play clicked song
      if(nuProps.redux.current_song._id !== this.props.redux.current_song._id){
        
        // Remove previously loaded song and/or add new one
        if(window.global_audio_object && window.global_audio_object.pause){ 
          window.global_audio_object.pause()
          window.global_audio_object = null
        }

        // Load new song and play
        window.global_audio_object = new Audio(`${nuProps.redux.current_song.audio.metadata.url}`)
        window.global_audio_object.addEventListener('canplaythrough', function() { 
          window.global_audio_object.play();
          console.log({audio_object: window.global_audio_object})
        }, false);
        this.time = setInterval(()=> {
          this.props.setCurrentTime(Math.trunc(window.global_audio_object.currentTime))
        }, 1000)
      }

      // Stop current song
      if(nuProps.redux.current_song._id === this.props.redux.current_song._id && nuProps.redux.is_paused){
        window.global_audio_object.pause()
      }

      // Play current song
      if(nuProps.redux.current_song._id === this.props.redux.current_song._id && !nuProps.redux.is_paused && this.props.redux.is_paused){
        window.global_audio_object.play()

      }
    }
  }
  playPause = async () => {
    if(this.props.redux.is_paused){
      await this.props.isPaused(false)
    } else {
      await this.props.isPaused(true)
    }
  }
  render() {
    const song = this.props.redux.current_song
    return (
      <div id="soundcloud-player" style={this.state.open ? {height: 50, padding: 10} : {height: 0, padding: 0, overflow: 'hidden'}}>
        {
          this.state.open && <div dangerouslySetInnerHTML={{__html: `<style>footer{margin-bottom: 50px}</style>`}} />
        }
      { song ? 
          <div id="player-meta-wrap" style={{opacity: 1}}>
            <div id="player-song-image">
              <img src={`${song.image.metadata.url}?mode=resize-webp&width=100&height=100`} alt={this.props.redux.current_song.artist+' - '+this.props.redux.current_song.title} />
            </div>
            <div id="player-song-title">
            {
              this.props.redux.current_song.artist+' - '+this.props.redux.current_song.title
            }
            </div>
            <div id="play-song-time">
              { this.props.redux.current_time === 0 ? <i style={{marginRight: 15}} className="spinning fas fa-spinner"></i> : this.props.redux.current_time }
            </div>
            <i
              onClick={this.playPause}
              className={
                  this.props.redux.is_paused ?
                    "fas fa-play-circle"
                  :
                    "fas fa-pause-circle"
            }></i>

            <div className="volume-container">
              <i className="fa fa-volume-down"></i>
              <div id="volume"></div>
              <i className="fa fa-volume-up"></i>
            </div>

          </div>
        :
        <p>Select a song to play</p>
      }
      </div>
    )
  }
}

function mapStateToProps(redux) {
  return {
    redux
  }
}

function mapDispatchToProps(dispatch){
  return bindActionCreators(ActionCreators, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(AudioPlayer)