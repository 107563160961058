import createReducer from '../lib/createReducer'
import * as types from '../actions/types'

export const settings = createReducer({},{
	[types.SITE_SETTINGS](state, action){
		let newState = Object.assign({}, action.payload)
		return newState
	}
})

export const eggs = (state = [], action) => {
	switch(action.type) {
		case 'GET_EGGS_PENDING':
				return {
						isLoading: true
				}
				break;
		case 'ADD_EGG':
					return [...state, action.payload.data];
				break;
		case 'GET_EGGS_REJECTED':
				return false
				break;
		case 'GET_EGGS_FULFILLED':
			return  action.payload.data;
		default:
				return state
	}
}
