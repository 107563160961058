import React, { Component } from 'react'
import { BrowserRouter as Router, Route } from 'react-router-dom'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { ActionCreators } from '../../redux/actions/index'
import TextInput from '../UI/TextInput'
import Button from '../UI/Button'
 
import '../../css/forms.css'

class Settings extends Component<{}> {
  constructor(props){
    super(props)
  
    this.state = {  
      email: this.props.redux.user.email,
      password: 'xxxxxxxxx',
      confirm_password: '',
      new_link_title: '',
      new_link_url: '',
      ...this.props.redux.user
    }

    this.debounce = ''
  }

  handleChange = async ({value, prop}) => {
    clearTimeout(this.debounce)
    console.log({value, prop})
    this.setState({[prop]: value})
    this.debounce = setTimeout(async ()=>{
      let settings = await fetch('/api/settings', {
        method: 'post',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({prop, value})
      }).then(res=>res.json()).catch(e=>console.log({e}))
      this.props.siteSettings(settings)
    }, 300)
  }

  getSiteSettings = async () => {
    let settings = await fetch('/api/settings').then(r=>r.json())  
    let newState = Object.assign({}, settings, this.state)
    console.log({settings})
    this.setState(newState)
  }

  async componentDidMount(){
    this.getSiteSettings()
  }

  handleLinkChange = async ({value, prop}, index) => {
    let links = [...this.state.links]
    links[index][prop] = value;
    this.handleChange({prop: 'links', value: links})
  }

  uploadNewLink = () => {
    let links = [...this.state.links]
    if(this.state.new_link_url && this.state.new_link_title)
    links.unshift({url: this.state.new_link_url, title: this.state.new_link_title})
    this.setState({new_link_url: '', new_link_title: ''})
    this.handleChange({prop: 'links', value: links})
  }

  deleteLink = async (index) => {

    let currentLinks = [...this.state.links]
    currentLinks.splice(index, 1);

    let settings = await fetch('/api/settings', {
      method: 'post',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({prop: 'links', value: currentLinks})
    }).then(res=>res.json()).catch(e=>console.log({e}))
    
    if(!settings) return alert('Oops, there was a server error')
    
    this.setState({links: currentLinks})
    console.log({links: [...settings.links]})
    this.props.siteSettings(settings)

  }

  moveSortableItemUp = (index) => {
    let allLinks = [...this.state.links]
    let item = allLinks.splice(index, 1)[0]
    allLinks.splice(index-1, 0, item);

    console.log({prop: 'links', value: allLinks})
    this.handleChange({prop: 'links', value: allLinks})
  }

  moveSortableItemDown = (index) => {
    let allLinks = [...this.state.links]
    let item = allLinks.splice(index, 1)[0]
    console.log({item, allLinks})
    allLinks.splice(index+1, 0, item);

    console.log({prop: 'links', value: allLinks})
    this.handleChange({prop: 'links', value: allLinks})
  }

  render() {
    return (
      <div id="admin-panel" className="container" style={{maxWidth: 900, minHeight: '100vh'}}>
        <h1>{this.props.redux.settings.artist_name}'s Artist Panel</h1>
        <div className="row">
          <div className="col-sm-6">
            <h2>Artist Info</h2>
            <TextInput width="100%" value={this.state.artist_name} name="artist_name" onChange={this.handleChange} placeholder="Artist Name"/><br/>
            <TextInput width="100%" value={this.state.artist_location} name="artist_location" onChange={this.handleChange} placeholder="Artist Location"/><br/><br/>
          </div>
          <div className="col-sm-6">
            <h2>MGMT Info</h2>
            <TextInput width="100%" value={this.state.contact_city} name="contact_city" onChange={this.handleChange} placeholder="Contact City"/><br/>
            <TextInput width="100%" value={this.state.contact_state} name="contact_state" onChange={this.handleChange} placeholder="Contact State"/><br/>
            <TextInput width="100%" value={this.state.contact_phone} name="contact_phone" onChange={this.handleChange} placeholder="Contact Phone"/>
          </div>
        </div>
        <div className="row">
          <div className="col-sm-6">
            <h1>Login Information</h1>
            <TextInput width="100%" value={this.state.email} name="email" onChange={this.handleChange} placeholder="Email"/>
            <TextInput width="100%" value={this.state.password} name="password" type="password" onChange={this.handleChange} placeholder="New Password"/>
            <TextInput width="100%" value={this.state.confirm_password} name="confirm_password" type="password" onChange={this.handleChange} placeholder="Confirm Password"/>
          </div>
        </div>
        <div className="row">
          <div className="col-sm-6">
            <h1>Featured Section (Homepage)</h1>
            <input type="checkbox" name="use_featured" checked={this.state.use_featured} onChange={(event)=>this.handleChange({value: event.target.checked, prop: 'use_featured'})}/>
            { this.state.use_featured && <>
                <TextInput width="100%" value={this.state.featured_iframe} name="featured_iframe" onChange={this.handleChange} placeholder="Featured: Iframe"/>
                <TextInput width="100%" value={this.state.featured_title} name="featured_title" onChange={this.handleChange} placeholder="Title"/>
                <TextInput width="100%" value={this.state.featured_subtitle} name="featured_subtitle" onChange={this.handleChange} placeholder="Subtitle"/>
                <TextInput width="100%" value={this.state.featured_link} name="featured_link" onChange={this.handleChange} placeholder="Link"/>
              </>
            }
          </div>
        </div>
        <div className="row">
          <div className="col-sm-6">
            
            <h1>Link Tree</h1>
            <div id="sortable-links" onClick={ this.sortableClickHandler }>
              { this.state.links && this.state.links.map((link, index)=>
                <div key={index} style={{border: 'solid 1px rgba(0,0,0,0.1)', background: 'rgba(0,0,0,0.05)', padding: 5, marginBottom: 16}}>
                  <TextInput width="100%" value={link.url} name={"url"} onChange={(updates)=>this.handleLinkChange(updates, index)} placeholder="Link URL"/>
                  <TextInput width="100%" value={link.title} name={"title"} onChange={(updates)=>this.handleLinkChange(updates, index)} placeholder="Link Title"/>
                  
                  <div style={{display: 'flex', justifyContent: 'space-between', marginTop: 10}}>
                    
                    <div>
                      <Button text="Move Up" onClick={()=>this.moveSortableItemUp(index)} style={{marginRight: 16}} />
                      <Button text="Move Down" onClick={()=>this.moveSortableItemDown(index)} />
                    </div>
                    
                    <div>
                      <Button color="#d00" text="delete" onClick={()=>this.deleteLink(index)} style={{borderColor: "#d00"}} />
                    </div>  

                  </div>
                </div>
                )
              }
            </div>
          </div>
          <div className="col-sm-6">
              
            <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'center', alignContent: 'center'}}>
              <h2>New Link</h2>
              <TextInput width="100%" name="new_link_title" value={this.state.new_link_title} onChange={(data)=>this.setState({new_link_title: data.value})} placeholder="New Link Title"/>
              <TextInput width="100%" name="new_link_url" value={this.state.new_link_url} onChange={(data)=>this.setState({new_link_url: data.value})} placeholder="New Link URL"/>
              <Button text="Add Link" onClick={this.uploadNewLink} style={{marginTop: 15, marginBottom: 15}}/>
            </div>

          </div>
        </div>
      </div>
    )
  }
}

function mapStateToProps({user, settings}) {
  return {
    redux: {
      user,
      settings
    }
  }
}

function mapDispatchToProps(dispatch){
  return bindActionCreators(ActionCreators, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(Settings)