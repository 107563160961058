import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import axios from 'axios'
import { ActionCreators } from '../../redux/actions/index'
import Button from '../UI/Button'
import NewEggPopup from './NewEggPopup'
import { humanizeDate } from '../../lib/date'
import { server } from '../../config'

class EggHunt extends Component<{}> {
  constructor(props){
    super(props)

    this.state = {
      fileprogress: 0,
    }
  }
  async componentDidMount(){
  }
  toggleNewReleasePopup = () => {
    if(this.state.create_new_release){
      this.setState({create_new_release: false})
    } else {
      this.setState({create_new_release: true})
    }
  }
  handleInputchange = ({value, prop}) => {
    this.setState({[prop]: value})
  }
  uploadEgg = async (form) => {
    axios.request( {
      method: 'post', 
      url: '/api/egg', 
      data: form, 
      onUploadProgress: (p) => {
        console.log(p); 
        this.setState({
          fileprogress: p.loaded / p.total
        })
      }
    }).then (uploaded => {
      this.setState({
        fileprogress: 1.0,
      })
      if(uploaded){
        this.setState(this.baseState, ()=>this.props.addNewEgg(uploaded.data))
      }
    }).catch(error=>console.error({error_from: 'publishRelease()', error}))
  }

  togglePanel = (_id) => {
    const pannel = document.getElementById(_id)
    pannel.classList.toggle("open")
  }

  render() {
    return (
      <div className="container" id="releases-page" style={{maxWidth: 900}}>
        <h1>Egg Manager</h1>
        
        <Button onClick={this.toggleNewReleasePopup} text="SCHEDULE NEW" />
        <br/><br/>
        {
          this.props.redux.eggs.length > 0 && this.props.redux.eggs.map(egg => <div key={egg._id}>
          <div className="egg-card row" id={egg._id}>
            <div className="col-md-12" style={{display: 'flex', justifyContent: 'space-between'}}>
              
              <div style={{display: 'flex', alignItems: 'center'}}>
                { egg.image &&
                  <div className="image">
                    <img src={egg.image.metadata.url} alt="" />
                  </div>
                }
                <h2>{egg.title}</h2>
              </div>
              <h2 onClick={()=>this.togglePanel(egg._id)}>⬇️</h2>
            </div>
            <div className="pannel">
              <div className="col-md-6">
                  <p>EXCERPT: {egg.excerpt}</p>
                  <p>COMMAND: {egg.cli_command}</p>
                  <p>MAX UNLOCKS: {egg.max_unlocks}</p>
                  <p>RELEASE DATE: {humanizeDate(egg.release_date)}</p>
                  <p>EXPIRE DATE: {humanizeDate(egg.expire_date)}</p>
                  { egg.audio &&
                    <a href={egg.audio.metadata.url}><p>{egg.audio.metadata.original_filename}</p></a>
                  }
              </div>
              <div className="col-md-6">
                { egg.video && <>
                  <video controls>
                    <source src={egg.video.metadata.url} type={egg.video.contentType} />
                    Your browser does not support the video tag.
                  </video>
                  <p>{egg.video.metadata.original_filename}</p>
                </>}
              </div>
            </div>
          </div>
          <hr />
          </div>)
        }
        <NewEggPopup
          {...this.state}
          uploadEgg={this.uploadEgg}
          handleInputchange={this.handleInputchange}
          togglePopup={this.toggleNewReleasePopup} 
          uploadProgress={this.state.fileprogress}
        />

      </div>
    )
  }
}
function mapStateToProps(redux) {
  return {
    redux
  }
}
function mapDispatchToProps(dispatch){
  return bindActionCreators(ActionCreators, dispatch)
}
export default connect(mapStateToProps, mapDispatchToProps)(EggHunt)