import * as types from './types'
import axios from 'axios'

export function siteSettings( settings ) {
	return {
		type: types.SITE_SETTINGS,
		payload: settings
	}
}

export function getEggs() {
	return {
		type: 'GET_EGGS',
		payload: axios.get('/api/eggs')
	}
}

export function addNewEgg( egg ){
	return {
		type: 'ADD_EGG',
		payload: egg
	}
}