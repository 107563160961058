import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { NavLink as Link } from 'react-router-dom'
import { connect } from 'react-redux'
import { ActionCreators } from '../redux/actions/index'
import '../css/social.css'
import SideMenu from './SideMenu'
import Input from './UI/TextInput'
import Button from './UI/Button'
import { signupForNewsletter } from '../lib/mailchimp'
import { production, server } from '../config'

const custom_icon_style = {
  padding: 4,
  background: 'rgb(14, 20, 30)',
  borderRadius: 100,
  width: 40,
  height: 40,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  marginTop: 10
}
const menuStyles = {
  background: '#111',
  paddingLeft: 30,
  paddingRight: 30,
  width: '100%',
  height: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center'
}
const inputStyles = {
  width: '100%'
}
class SocialIcons extends Component<{}> {

  constructor(props){
    super(props)
  
    this.state = {
      posX: 'right',
      posY: 'top'
    }
  }


  flipSides = () => {
    this.setState({
      posX: this.state.posX === 'right' ? 'left' : 'right'
    })
  }
  
  topBottom = () => {
    this.setState({
      posY: this.state.posY === 'top' ? 'bottom' : 'top'
    })
  }
  
  paths = ['/admin']
  
  runRiddle = () => {
    console.log('You cant catch me I\'m the fuckin ginger bread man...')
  }
  
  toggleMailchimpSignup = () => {
    this.setState({open_mailChimp: !this.state.open_mailChimp})
  }
  
  emailSignup = async () => {
    this.setState({sending: true})
    const response = await signupForNewsletter({email: this.state.email})
    
    if(response.code < 300 || response.email){
      this.setState({email_response: 'You have successfully signed up!'}, () => setTimeout( () => { this.setState({email_response: '', sending: false, email: ''}) }, 3000))
    } else {
      this.setState({email_response: 'There seems to have been an error, please try again'}, () => setTimeout( () => { this.setState({email_response: '', sending: false, email: ''}) }, 3000))
    }
  }
  
  handleEmailInputChange = ({value, prop}) => {
    this.setState({email: value})
  }
  
  render() {
    if(this.props.history.location.pathname.indexOf('admin') > -1){
      return <span></span>
    } else {
      return (
        <>
        <SideMenu open={this.state.open_mailChimp} style={menuStyles}>
          <div style={{paddingTop: 30, paddingBottom: 120, overflowX: 'hidden'}}>
            <p style={{fontSize: 60, margin: 0}}>SUBSCRIBE</p>
            <p style={{fontSize: 32}}>TO OUR NEWSLETTER</p>
            <img src={require('../images/social/mail-white.png').default} alt="" style={{width: 80, display: 'block', margin: '30px auto 0'}}/>
            <Input
              color="#fff"
              width="100%"
              placeholder="Your Email"
              onChange={this.handleEmailInputChange}
              value={this.state.email}
            />
            {
              this.state.sending ? 
                <img className="fb-logo spinning-pulse" src="/images/loading.svg" alt="loading" style={{
                  margin: 0,
                  height: 34,
                  marginTop: 15,
                  marginBottom: 15,
                  marginLeft: 'auto',
                  marginRight: 'auto',
                  display: 'block'
                }}/>
              :
              <Button
                text="Signup"
                style={{width: "100%", marginTop: 15, borderColor: '#fff', fontWeight: '800', marginBottom: 15}}
                background="transparent"
                color="#fff"
                onClick={this.emailSignup}
              />
            }
            <p>{ this.state.email_response || `Signup for the ohshutit.com mailing list and find out about about new releases and terminal command releases in the member zone` }</p>
          </div>
        </SideMenu>
        <div className="social-wrap" style={{[this.state.posX]: 0, [this.state.posY]: this.state.posY === 'bottom' && this.props.redux.current_song ? 50 : 0}}>
          <div>
            {this.state.posY === 'bottom' && 
              <div onClick={this.topBottom} style={{
                marginTop: 10,
                width: '100%',
                textAlign: 'center',
                borderRadius: 3,
                background: '#333',
                color: '#fff',
                minHeight: 20,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}>
                <i className="fas fa-chevron-circle-up"></i>
              </div>
            }
            {/* <span onClick={this.props.openContactForm}>
              <img src="/images/social/envelope.png" className="social-icons" alt="Email"/>
            </span> */}
            {
              this.props.history.location.pathname !== '/' &&
              <Link to="/">
                <img src="/images/back.png" className="social-icons" alt="GoBack()"/>
              </Link>
            }

            {
              (this.props.history.location.pathname === '/' || this.props.history.location.pathname === '/links') &&
              <div>
            {
              this.props.redux.user.login ?
              <a href="https://discord.com/channels/707614343188971530/902797853536157716">
                <img className="avatar" src={this.props.redux.user.pfp || require('../images/social/discord.png').default} alt={this.props.redux.user.discord.username} style={{width: 40, height: 40, borderRadius: 20, marginTop: 10}} />
              </a>
              :
              <a target="_blank" href="https://discord.gg/nzXynMBY4t" rel="noopener noreferrer">
                <img src="/images/social/discord-animated.gif" className="social-icons" alt="Instagram" style={{borderRadius: 100}}/>
              </a>
            }
                
                <a target="_blank" href="https://www.instagram.com/ohshutit/" rel="noopener noreferrer">
                  <img src="/images/social/insta.png" className="social-icons" alt="Instagram"/>
                </a>
                <a target="_blank" href="https://open.spotify.com/artist/6sxubtAMXZBk6Vcm1UNZ6h?si=NcSoDwnuR9uO0Wdm0TXd1g" rel="noopener noreferrer">
                  <img src="/images/social/spotify.png" className="social-icons" alt="Spotify"/>
                </a>
                <a target="_blank" href="https://soundcloud.com/ohshutit" rel="noopener noreferrer">
                  <img src="/images/social/soundcloud.png" className="social-icons" alt="Soundcloud"/>
                </a>
                <a target="_blank" href="https://www.facebook.com/ohshutit" rel="noopener noreferrer">
                  <img src="/images/social/fb.png" className="social-icons" alt="Facebook"/>
                </a>
                <img
                  onClick={this.toggleMailchimpSignup}
                  style={{boxShadow: '1.5px 2px 3px rgba(0,0,0,0.2)', borderRadius: '100%', position: 'relative', zIndex: 9999}}
                  src={require('../images/social/mailchimp.png').default}
                  className="social-icons"
                  alt="Signup for our mailinglist"
                />
                <Link to="/links">
                  <img src={require('../images/social/links.gif').default} className="social-icons" alt="Links"/>
                </Link>
              </div>
            }
            {
              this.props.history.location.pathname === '/hack' ?
              <a onClick={this.runRiddle}>
                <img src="/images/question-mark.png" className="social-icons" alt="hello?"/>
              </a>
              :
              this.props.redux.user.login && <Link to="/hack">
                <img src="/images/terminal.png" className="social-icons" alt="Terminal"/>
              </Link>
            }
            {
              !this.props.redux.user.login ? 
                <a href={server+"/auth/discord/signin"} style={{ color: "#ff7400", fontWeight: 800, fontSize: 10.2, textAlign: 'center' }}>
                    <img src="/images/sign-in.png" className="social-icons" alt="Signup"/>
                    SIGN IN
                </a>
                :
                <a href="/logout">
                  <img src="/images/sign-out.png" className="social-icons" alt="Signup"/>
                </a>
            }
            <div onClick={this.flipSides} style={{marginTop: 10, width: '100%', textAlign: 'center', borderRadius: 3, background: '#333', color: '#fff'}}>
              FLIP
            </div>
            {this.state.posY === 'top' && 
              <div onClick={this.topBottom} style={{
                marginTop: 10,
                width: '100%',
                textAlign: 'center',
                borderRadius: 3,
                background: '#333',
                color: '#fff',
                minHeight: 20,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}>
                <i className="fas fa-chevron-circle-down"></i>
              </div>
            }
          </div>
        </div>
        </>
      )
    }
  }
}

function mapStateToProps(redux) {
  return {
    redux
  }
}

function mapDispatchToProps(dispatch){
  return bindActionCreators(ActionCreators, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(SocialIcons)