import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { ActionCreators } from '../redux/actions/index'
import { onWindowResize } from '../lib'

import '../css/contact.css'

class Contact extends Component<{}> {
  componentDidMount(){
    let contact_form = document.getElementById('contact-artist')
    contact_form.style.minHeight = window.innerWidth > 768 ? window.innerHeight + 'px' : ((window.innerHeight / 3) * 2) + 'px'
    onWindowResize(()=>{
      let contact_form = document.getElementById('contact-artist')
      if(window.innerWidth > 768 && contact_form){
        contact_form.style.minHeight = window.innerWidth > 768 ? window.innerHeight + 'px' : ((window.innerHeight / 3) * 2) + 'px'
      }
    })
  }
  render() {
    return (
       <section id="contact-artist" className="row" style={{background: '#000'}}>
          <p className="contact-header">CONTACT</p>
          { this.props.redux.settings ? 
            <p>z@zyon.io - {this.props.redux.settings.contact_city || "chicago"} - {this.props.redux.settings.contact_state || "los angeles"} - {this.props.redux.settings.contact_phone || '773.480.6350'}</p>
            :
            <p>z@zyon.io - chicago - los angeles - 773.480.6350</p>
          }
       </section>
    )
  }
}

function mapStateToProps({settings}) {
  return {
    redux: {
      settings
    }
  }
}

function mapDispatchToProps(dispatch){
  return bindActionCreators(ActionCreators, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(Contact)