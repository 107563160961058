import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { ActionCreators } from '../../redux/actions/index'
import './css/popups.css'

let timer
const dimensions = {
  width: '100vw',
    height: '100vh'
}
class Popup extends Component<{}> {
  constructor(props){
    super(props)
  
    this.state = {
      open: false,
      width: window.innerWidth,
      height: window.innerHeight
    }
  }
  componentDidMount(){
    // handle event
        this.setState({
          width: window.innerWidth,
          height: window.innerHeight
        })
  }
  render() {
    return (
      <div className="popup-container" style={this.props.open ? {display: 'flex', zIndex: 9999, ...dimensions, ...this.props.style} : {display: 'none'}}>
        { !this.props.customCloseButton && <i onClick={this.props.togglePopup} className="fas fa-times close-popup" style={this.props.closeButtonPosition && {[this.props.closeButtonPosition] : 0 } }></i>}
        {this.props.children}
      </div>
    )
  }
}

function mapStateToProps(redux) {
  return {
    redux
  }
}

function mapDispatchToProps(dispatch){
  return bindActionCreators(ActionCreators, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(Popup)
