import React, { Component } from 'react'
import Popup from '../UI/Popup'
import TextInput from '../UI/TextInput'
import DatePicker from '../UI/DatePicker'
import Switch from '../UI/Switch'
import Button from '../UI/Button'
import FileUpload from '../UI/FileUpload'

class NewReleasePopup extends Component<{}> {
  constructor(props){
    super(props)
  
    this.state = {
      uploadingRelease: false
    }
    this.formData = new FormData()
  }
  handleFileUpload = (label, file) => {
    console.log({file, label})
    this.formData.append(label, file[0] || file)
  }
  saveRelease = async () => {
    this.setState({uploadingRelease: true})
    let propNames = Object.getOwnPropertyNames(this.props)
    propNames.forEach(name => {
      if(name !== 'publishRelease' && name !== 'handleInputchange' && name !== 'togglePopup')
      this.formData.append([name], this.props[name])
    })
    await this.props.publishRelease(this.formData)
    this.setState({uploadingRelease: false})
    console.log('BEFORE')
    this.props.togglePopup()
    console.log('AFTER')
  }
  componentDidMount = () => {
    console.log({release_open: this.props.create_new_release})
  }
  render() {
    return (
      <Popup open={this.props.create_new_release} customCloseButton togglePopup={this.props.togglePopup} closeButtonPosition="right">
        <div className="new-release-inner-wrap" style={{maxWidth: 900, width: '100%'}}>
        <h1 style={{color: '#fff'}}>Schedule a New Release</h1>
          <div className="row">
            <div className="col-sm-6">
              <TextInput completedColor={'#52c1f7'} icon="fas fa-user-ninja" color="#fff" width={"100%"} name="artist" value={this.props.artist} placeholder="Artist Name" onChange={this.props.handleInputchange}/>
            </div>
            <div className="col-sm-6">
              <TextInput completedColor={'#52c1f7'} icon="fas fa-music" color="#fff" width={"100%"} name="title" value={this.props.title} placeholder="Song Title" onChange={this.props.handleInputchange}/>
            </div>
          </div>
          <div className="row">
            <div className="col-sm-6">
              <TextInput completedColor={'#1db954'} icon="fab fa-spotify" color="#fff" width={"100%"} name="spotify" value={this.props.spotify} placeholder="Spotify Song Link" onChange={this.props.handleInputchange}/>
            </div>
            <div className="col-sm-6">
              <TextInput completedColor={'#1db954'} icon="fab fa-spotify" color="#fff" width={"100%"} name="spotify_embed" value={this.props.spotify_embed} placeholder="Spotify Embed URL" onChange={this.props.handleInputchange}/>
            </div>
          </div>
          <div className="row">
            <div className="col-sm-6">
              <TextInput completedColor={'#1db954'} icon="fab fa-spotify" color="#fff" width={"100%"} name="spotify_uri" value={this.props.spotify_uri} placeholder="Spotify URI" onChange={this.props.handleInputchange}/>
            </div>
            <div className="col-sm-6">
              <TextInput completedColor={'#52c1f7'} icon="fas fa-cloud-download-alt" color="#fff" width={"100%"} name="buy" value={this.props.buy} placeholder="Purchase Link" onChange={this.props.handleInputchange}/>
            </div>
          </div>
          <div className="row">
            <div className="col-sm-6">
              <TextInput completedColor={'#ff7700'} icon="fab fa-soundcloud" color="#fff" width={"100%"} name="soundcloud" value={this.props.soundcloud} placeholder="Soundcloud Link" onChange={this.props.handleInputchange}/>
            </div>
            <div className="col-sm-6">
              <TextInput completedColor={'#ff7700'} icon="fab fa-soundcloud" color="#fff" width={"100%"} name="soundcloud_embed" value={this.props.soundcloud_embed} placeholder="Soundcloud Embed URL" onChange={this.props.handleInputchange}/>
            </div>
          </div>
          <div className="row">
            <div className="col-sm-6">
              <DatePicker
                completedColor={'#52c1f7'}
                icon="far fa-calendar-alt"
                color="#fff"
                width={"100%"}
                name="release_date"
                value={this.props.release_date}
                placeholder="Release Date"
                onChange={this.props.handleInputchange}/>
            </div>
            <div className="col-sm-6">
              <Switch style={{marginTop: 20}} color="#fff" width={"100%"} name="downloadable" value={this.props.download} text="Downloadable" onSwitch={this.props.handleInputchange}/>
            </div>
          </div>
          <div className="row">
            <div className="col-sm-12">
            <div style={{display: 'flex'}}>
              <FileUpload
                type="image"
                style={{maxWidth: 200, minWidth: 200, height: 200, borderRadius: 0, marginRight: 20}}
                fileData={this.handleFileUpload}
                id="artwork"
              />
              <FileUpload
                type="audio"
                style={{height: 200}}
                fileData={this.handleFileUpload}
                id="song"
              />
            </div>
            </div>
          </div>
          <div className="row">
            <div className="col-sm-12">
              <Button loading={this.state.uploadingRelease} text="PUBLISH RELEASE" className="success" style={{marginTop: 20}} onClick={this.saveRelease}/>
              <Button text="CANCEL" className="fail" style={{marginTop: 20}} onClick={this.props.togglePopup}/>
            </div>
          </div>
        </div>
      </Popup>
    )
  }
}

export default NewReleasePopup