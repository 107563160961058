import React, { Component } from 'react'
import { BrowserRouter as Router, Route, Switch, NavLink as Link } from 'react-router-dom'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { ActionCreators } from '../redux/actions/index'

import HeaderNav from '../components/admin/HeaderNav'
import Dashboard from '../components/admin/Dashboard';
import Settings from '../components/admin/Settings';
import Releases from '../components/admin/Releases';
import EggHunt from '../components/admin/EggHunt';
import Bio from '../components/admin/Bio';
import Gallery from './Admin/Gallery'
import RSS from './Admin/RSS'

const AdminController = (props) => {
    if(props.redux.user.login && (props.redux.user.super_admin || props.redux.user.team_member)){
      return (
        <div style={{height: 'calc(100% - 50px)'}} id="admin-panel">
          {
            props.redux.user.super_admin && <>
            <Route path="/admin" component={HeaderNav} />
            <Route exact path="/admin" component={Dashboard} />
            <Route path="/admin/releases" component={Releases} />
            <Route path="/admin/egg" component={EggHunt} />
            </>
          }
            <Route path="/admin/rss" component={RSS} />
          {
            props.redux.user.super_admin && <>
            <Route path="/admin/gallery" component={Gallery} />
            <Route path="/admin/bio" component={Bio} />
            <Route path="/admin/settings" component={Settings} /></>
          }
        </div>
      )
    } else {
      return (
        <div id="admin-panel" className="container" style={{paddingTop: 40,}}>
        { props.redux.user.unauthorized && props.redux.user.unauthorized !== 'unknown' &&
          <div>
            <h1>Oops!</h1>
            <h2>It seems you are trying to access the artist controls but you are not authenticated.</h2>
            <Link to="/admin-login"><button className="btn btn-success" style={{marginRight: 10}}>Login</button></Link>
            <Link to="/"><button className="btn btn-warning">Go Back</button></Link>
          </div>
        }
        </div>
      )
    }
}


function mapStateToProps(redux) {
  return {
    redux
  }
}

function mapDispatchToProps(dispatch){
  return bindActionCreators(ActionCreators, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(AdminController)