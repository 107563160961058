import * as userActions from './user'
import * as playerActions from './player'
import * as globalActions from './global'
import * as galleryActions from './gallery'
import * as reposterActions from './reposter'

export const ActionCreators = Object.assign({},
	userActions,
	playerActions,
	galleryActions,
	globalActions,
	reposterActions
)