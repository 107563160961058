import { combineReducers } from 'redux'
import * as userReducers from './user'
import * as globalReducers from './global'
import * as playerReducers from './player'
import * as galleryReducers from './gallery'
import * as reposterReducers from './reposter'

export default combineReducers(Object.assign(
	userReducers,
	playerReducers,
	globalReducers,
	galleryReducers,
	reposterReducers,
))