import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { ActionCreators } from '../../redux/actions/index'
import FileUpload from '../../components/UI/FileUpload'
import TextInput from '../../components/UI/TextInput'
import Button from '../../components/UI/Button'
import Popup from '../../components/UI/Popup'
import SimpleReactLightbox, { SRLWrapper } from 'simple-react-lightbox-pro'
import axios from 'axios'
import './css/gallery.css'

let propNames = ["title", "subtitle"]

class NewGalleryPopup extends Component<{}> {
  constructor(props){
    super(props)

    this.state = {
      images: [],
      title: '',
      subtitle: '',
      unlock_code: '',
    }
    this.baseState = this.state
    this.formData = new FormData()
  }

  handleFilePreview = (label, file, drop) => {
    if(!file) return false
    let reader = new FileReader()
    reader.readAsDataURL(drop ? file[0] : file)
    reader.onloadend = () => {
      this.setState({ images: this.state.images.concat({
        src: reader.result,
        title: 'no title',
        subtitle: 'no subtitle',
        label: label+'_'+(this.state.images.length+1)
      }) })
    }
    this.formData.append(label+'_'+(this.state.images.length+1), file[0] || file)
  }

  saveGallery = async () => {
    this.setState({uploadingGallery: true})
    let images = [...this.state.images]
    images = await this.saveImages(images)
    
    const galleryData = {
      images,
      title: this.state.title,
      subtitle: this.state.subtitle,
      unlock_code: this.state.unlock_code,
    }

    const gallery = await fetch('/api/gallery', {
      method: 'post',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(galleryData)
    }).then(r=>r.json())
    this.setState(this.baseState)
    const okay = await this.props.getGalleries()
    this.props.togglePopup()
  }

  saveImages = async (images) => {
    let all_image_meta = []

    for (let i = 0; i < images.length; i++) {
      let image = Object.assign({}, images[i]);
      delete image.src
      all_image_meta.push(image)
    }
    this.formData.append('image_meta', JSON.stringify(all_image_meta))
    
    if(this.state.unlock_code) this.formData.append('unlock_code', this.state.unlock_code)

    const savedImages = await axios.request( {
      method: 'post', 
      url: '/api/gallery/images', 
      data: this.formData, 
      onUploadProgress: (p) => {
        console.log({progress: p.loaded / p.total}); 
        this.setState({
          fileprogress: p.loaded / p.total
        })
      }
    }).then (uploaded => {
      this.setState({
        fileprogress: 1.0,
      })
      if(uploaded){
        return uploaded
      }
    }).catch(error=>console.error({error_from: 'saveImages()', error}))

    return savedImages.data
  }

  handleInputchange = ({value, prop}) => {
    this.setState({[prop]: value})
  }

  handleImageInputchange = ({prop, value}, index) => {
    let allImages = [...this.state.images]
    allImages[index][prop] = value
    this.setState({images: allImages}, ()=>console.log(this.state.images))
  }

  async componentDidMount(){
  }

  render() {
    return (
      <SimpleReactLightbox>
        <Popup open={this.props.openNewGalleryPopup} togglePopup={this.props.togglePopup} closeButtonPosition="right">
          <div className="main" style={{background: 'transparent', color: '#fff'}}>
            <div className="container">
              <div className="row" style={{maxWidth: 600, margin: 'auto'}}>
                <div className="col-sm-12">
                  <h1>New Gallery</h1>
                </div>
                <div className="col-sm-6">
                  <TextInput value={this.state.title} name="title" color="#fff" placeholder="Gallery Title" onChange={this.handleInputchange}/>
                </div>
                <div className="col-sm-6">
                  <TextInput value={this.state.subtitle} name="subtitle" color="#fff" placeholder="Gallery Subtitle" onChange={this.handleInputchange} />
                </div>
                <div className="col-sm-6">
                  <TextInput value={this.state.unlock_code} name="unlock_code" color="#fff" placeholder="Unlock Code" onChange={this.handleInputchange}/>
                </div>
              </div>
              <div className="row" style={{maxWidth: 600, margin: '40px auto'}}>
                <div className="col-sm-12">
                  <div className="row">
                    <SRLWrapper>
                    {
                      this.state.images.map((image, index) =>
                        <div key={index} className="col-sm-3" style={{ padding: '5px 5px 10px'}}>
                            <a href={image.src}>
                              <img
                                style={{maxWidth: '100%', borderRadius: 15,}}
                                src={image.src} 
                                srl_gallery_image="true" // Add this if your thumbnail is not recognized
                                alt={this.state.images[index].title}
                              />
                            </a>
                            <div  style={{marginTop: 15}} />
                              <TextInput
                                value={this.state.images[index].title}
                                name="title"
                                color="#fff"
                                placeholder="TItle"
                                onChange={(data)=>this.handleImageInputchange(data, index)}
                              />
                              <TextInput
                                value={this.state.images[index].subtitle}
                                name="subtitle"
                                color="#fff"
                                placeholder="Subtitle"
                                onChange={(data)=>this.handleImageInputchange(data, index)}
                            />
                        </div>)
                    }
                    </SRLWrapper>
                  </div>
                </div>
                <div className="col-sm-12">
                  <h1 style={{margin: '16px 0'}}>Add Images</h1>
                </div>
                <div className="col-sm-12">
                  <FileUpload
                    type="image"
                    noPreview
                    style={{width: 200, height: 200, borderRadius: 0, margin: '0 0 32px 0'}}
                    fileData={this.handleFilePreview}
                    id={"image_"+this.state.images}
                    uploadMessage="Upload your new gallery image here"
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="publish-gallery">
            <Button text="Upload" onClick={this.saveGallery} style="" color="white" background="green" width="200"/>
          </div>
        </Popup>
      </SimpleReactLightbox>
    )
  }
}
function mapStateToProps(redux) {
  return {
    redux
  }
}
function mapDispatchToProps(dispatch){
  return bindActionCreators(ActionCreators, dispatch)
}
export default connect(mapStateToProps, mapDispatchToProps)(NewGalleryPopup)