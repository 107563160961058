import React from 'react'
import { NavLink as Link } from 'react-router-dom'

export default function Footer({light}) {

  const styles = {
    termsCond: {
      display: 'inline-block',
      width: 150,
      color: light ? '#333' : '#777'
    }
  }
  return (
    <footer style={{marginLeft: -15, marginRight: -15, padding: 15, textAlign: 'center', borderTop: light ? 'solid 2px #eee' : 'solid 2px #0e141e', background: light ? '#fff' : '#000'}}>
      <p style={{margin: '0 0 15px  0', color: light ? '#333' : '#777'}}>© COPYRIGHT 2020. ALL RIGHTS RESERVED. HU$H</p>
      <p style={{margin: 0, color: '#777'}}><Link style={{textAlign: 'right', ...styles.termsCond}} to="/privacy-policy">Privacy Policy</Link> | <Link style={{textAlign: 'left', ...styles.termsCond}} to="/terms">Terms &amp; Conditions</Link></p>
    </footer>
  )
}
