import React, { Component } from 'react'
import { BrowserRouter as Router, Route } from 'react-router-dom'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { ActionCreators } from '../redux/actions/index'
import TextInput from '../components/UI/TextInput'
import '../css/admin.scss'
import { server } from '../config'

class AdminLogin extends Component<{}> {
  constructor(props){
    super(props)
  
    this.state = {
      email: '',
      password: '',
    }
  }
  handleInputChange = ({value, prop}) => {
    this.setState({
      [prop]: value
    })
  }
  render() {
    return (
      <div id="login-page" className="container" style={{
        minHeight: 400,
        maxWidth: 900,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column'
      }}>
        <h1 style={{
          fontSize: 65,
          marginBottom: 10
        }}>{this.props.redux.settings.artist_name}</h1>
        <form
          action="/auth/login"
          method="POST"
          style={{
            width: 300,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'column'
        }}>
          <TextInput
            style={{marginRight: 0}}
            width="100%"
            name="email"
            value={this.state.email}
            onChange={this.handleInputChange}
            placeholder="Email"
          />
          <TextInput
            type="password"
            style={{marginRight: 0}}
            width="100%"
            name="password"
            value={this.state.password}
            onChange={this.handleInputChange}
            placeholder="Password"
          />
          <input
            type="submit"
            value="LOGIN"
            className="main-button"
            style={{
              width: '100%',
              fontWeight: 800,
              marginTop: 10
            }}
          />
        </form>
        <div style={{backgroundColor: '#111', padding: '0 30px', marginTop: 15, borderRadius: 15}}>
          <a href={`${server}/auth/facebook`} style={{textDecoration: 'none'}}>
            <button className="facebook-signup-btn">
              <img className="fb-logo pulse" src="/images/facebook-logo.png" alt="FB Logo"/>
              <img className="fb-logo lit pulse" src="/images/facebook-logo-blue.png" alt="FB Logo"/>
              &nbsp;SIGNUP WITH FACEBOOK
            </button>
          </a>
      </div>
      </div>
    )
  }
}

function mapStateToProps(redux) {
  return {
    redux
  }
}

function mapDispatchToProps(dispatch){
  return bindActionCreators(ActionCreators, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(AdminLogin)