import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { ActionCreators } from '../redux/actions/index'
import PlayListItem from './PlaylistItem'
// import { onWindowResize } from '../lib'
// import { all } from '../lib/releases'

import '../css/playlist.css'
class Playlist extends Component<{}> {
  constructor(props){
    super(props)
  
    this.state = {
      songs: false
    }
  }
  render() {
    const { songs } = this.state
    return (
      <section id="playlist" className="row" style={{minHeight: window.innerHeight, flexDirection: 'column'}}>

      { this.props.redux.settings.use_featured &&<>
        <div className="featured-home center">
          {this.props.redux.settings.featured_title && <h2>{this.props.redux.settings.featured_title}</h2>}
          {this.props.redux.settings.featured_subtitle && <h3>{this.props.redux.settings.featured_subtitle}</h3>}
          {this.props.redux.settings.featured_iframe && <div dangerouslySetInnerHTML={{__html:this.props.redux.settings.featured_iframe}} />}
        </div>
      </>
      }
        <div style={{
          marginTop: this.props.redux.settings.use_featured  && 0,
          marginBottom: this.props.redux.settings.use_featured && 60,
          marginLeft: 'auto',
          marginRight: 'auto',
          maxWidth: 1024, }}
          className="playlistWrapper">
          { 
            this.props.redux.all_songs && this.props.redux.all_songs.map((song, index) => <PlayListItem key={index} release={song} itemIndex={index}/>)
          }
        </div>
      </section>
    )
  }
}

function mapStateToProps(redux) {
  return {
    redux
  }
}

function mapDispatchToProps(dispatch){
  return bindActionCreators(ActionCreators, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(Playlist)