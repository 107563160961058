import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { ActionCreators } from '../redux/actions/index'
import Hero from '../components/Hero'

import '../../node_modules/aos/dist/aos.css'; 
import AOS from 'aos'; 
import Playlist from '../components/Playlist';
import Biography from '../components/Biography';
import Contact from '../components/Contact';
import Footer from '../components/Footer';

class Home extends Component<{}> {
  constructor(props, context) { 
    super(props, context); 
    AOS.init(); 
  } 
  componentWillReceiveProps(){ 
    AOS.refresh(); 
  } 
  render() {
    return (
      <div className="page container-fluid" id="main">
        <Hero />
        <Playlist />
        <Biography />
        <Contact />
        <Footer />
      </div>
    )
  }
}

function mapStateToProps(redux) {
  return {
    redux
  }
}

function mapDispatchToProps(dispatch){
  return bindActionCreators(ActionCreators, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(Home)