import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { ActionCreators } from '../redux/actions/index'
import { onWindowResize } from '../lib'
import '../css/bio.css'

import zipperFace from '../images/zipper-face.jpg'
import zipperFaceWhite from '../images/zipper-face-white.jpg'

class Biography extends Component<{}> {
  constructor (props){
    super(props)
    this.state = {

    }
  }
  componentDidMount(){
    let bio_left = document.getElementById('bio-left')
    let bio_right = document.getElementById('bio-right')
    bio_right.style.minHeight = window.innerWidth > 768 ? window.innerHeight+'px' : ((window.innerHeight / 3) * 2) + 'px'
    bio_left.style.height = window.innerWidth > 768 ? bio_right.offsetHeight + 'px' : ((window.innerHeight / 3) * 2) + 'px'
    onWindowResize(()=>{
      let bio_left = document.getElementById('bio-left')
      let bio_right = document.getElementById('bio-right')
      if(window.innerWidth > 768 && bio_right && bio_left){
        bio_right.style.minHeight = window.innerWidth > 768 ? window.innerHeight+'px' : ((window.innerHeight / 3) * 2) + 'px'
        bio_left.style.height = window.innerWidth > 768 ? bio_right.offsetHeight + 'px' : ((window.innerHeight / 3) * 2) + 'px'
      }
    })
  }
  componentWillReceiveProps(nuProps){
    if( !Object.is(JSON.stringify(this.props.redux.settings.bio), JSON.stringify(nuProps.redux.settings.bio)) ){
    } else if(!this.state.formatted_bio){
      let items = this.props.redux.settings.bio
      let formatted_bio = []
      if(items && items !== '<p><br></p>') {
        items.split('</p>').forEach((pgh, i) => {
          formatted_bio.push(pgh.replace('<p>', '<p data-aos="fade" data-aos-duration="1000">'))
        })
        formatted_bio = formatted_bio.join('')
        console.log({formatted_bio})
        this.setState({formatted_bio})
      }
    }
  }
  render() {
    return (
       <section id="artist-biography">
        <div className="row">
          <div id="bio-left" className="col-sm-6 left" style={styles.left}>
          <p className="bio-header" data-aos="fade" data-aos-duration="1000">BIO</p>
            <img src={zipperFace} className="bio-bg desktop" alt="Bio pic" />
            <img src={zipperFaceWhite} className="bio-bg mobile" style={{maxWidth: 400, right: 0}} alt="Bio pic"/>
          </div>
          <div id="bio-right" className="col-sm-6 right" style={styles.right}>
          { 
            this.state.formatted_bio && this.state.formatted_bio !== '<p><br></p>' ?
                <div dangerouslySetInnerHTML={{__html: this.state.formatted_bio}}></div>
              : 
                <div>
                  <p><span data-aos="fade" data-aos-duration="1000">Los Angeles based artist HU$H is the new music alias of</span><span data-aos="fade" data-aos-duration="1000"> Aaron Rich, who has worked under the names FUTR3, 8Barz, Les Boyz,</span><span data-aos="fade" data-aos-duration="1000"> Sub Militia (group), Sick System, and Science of the Subconscious. This </span><span data-aos="fade" data-aos-duration="1000">project is a blend of EDM, rock rifs, R&B and hip hop, with a sprinkle of pop.</span></p>
                  <p><span data-aos="fade" data-aos-duration="1000">The songs are about city life and the co-existence </span><span data-aos="fade" data-aos-duration="1000">of the shiny and the dirt, the things people see and the </span><span data-aos="fade" data-aos-duration="1000">things they don’t want to see. Ranging from topics such as self-serving</span><span data-aos="fade" data-aos-duration="1000"> addiction to money, power, and the dangerous fixations of feeling on top of the world.</span></p>
                  <p><span data-aos="fade" data-aos-duration="1000">From Memes to Movies Aaron’s music has been featured </span><span data-aos="fade" data-aos-duration="1000">in such releases as the viral video ‘Kazoo Kid - Trap Remix’</span><span data-aos="fade" data-aos-duration="1000"> with Mike Diva, the latest Orgy releases, the Iron Man 3 soundtrack, </span><span data-aos="fade" data-aos-duration="1000">collaborations with A Plus of Hieroglyphics and Myka Nyne of Freestyle fellowship, </span><span data-aos="fade" data-aos-duration="1000">as well as remixes of Jason Derulo, Mike Posner, </span><span data-aos="fade" data-aos-duration="1000">Taio Cruz, The Script, Wiz Khalifa, Far East Movement, Pitbull, Nicki Minaj, </span><span data-aos="fade" data-aos-duration="1000">and more... accumulating more than 100 million plays across multiple platforms. </span><span data-aos="fade" data-aos-duration="1000">Most recently as HU$H, he has worked with the riddim producer/dj Soltan on the song Sick, as</span><span data-aos="fade" data-aos-duration="1000"> well as dubstep producer Megalodon on Clap, featuring his verses as a rapper.</span></p>
                </div>
          }
          </div>
        </div>
       </section>
    )
  }
}

function mapStateToProps(redux) {
  return {
    redux
  }
}

function mapDispatchToProps(dispatch){
  return bindActionCreators(ActionCreators, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(Biography)



let styles = {
  left: {
    justifyContent: 'center',
    alignItems: 'center',
    display: 'flex',
    background: '#fff',
  },
}