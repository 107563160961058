import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { ActionCreators } from '../redux/actions'
import SimpleReactLightbox, { SRLWrapper } from 'simple-react-lightbox'
import { parseURLParamsAsObject } from '../lib/url'
import '../css/gallery.css'
class Gallery extends Component<{}> {
  constructor(props){
    super(props)

    this.state = {
      images: []
    }
  }
  async componentDidMount(){
    const { _id } = parseURLParamsAsObject()
    const gallery = await fetch('/api/gallery/'+_id).then(r=>r.json())

    if(gallery){
      this.setState({...gallery, found: true})
    } else {
      console.log({gallery})
    }
  }
  render() {
    return (
      <SimpleReactLightbox>
      <div className="container" style={{backgroundColor: "#000", height: '100vh', width: '100%', backgroundSize: 'cover', backgroundImage: this.state.unlock_code && `url(${require('../images/unlocked-bg.png').default})`}}>
        <h1 style={{color: "#fff", textAlign: 'center'}}>{this.state.unlock_code && "UNLOCKED: "}{this.state.title}</h1>
        <h2 style={{color: "#fff", textAlign: 'center'}}>{this.state.subtitle}</h2>
        <div className="container" style={{background: 'rgba(0,0,0,0.5)', padding: 30, borderRadius: 15, marginTop: 60, display: 'flex', alignContent: 'center', flexDirection: 'column', justifyContent: 'center'}}>
          <div className="row">
            <SRLWrapper>
              { this.state.images.map((image, index) => 
                <div key={index} className="col-sm-6 gallery-image-wrap unlocked">
                  <div className="background">
                    <a href={image.metadata.url}>
                      <img
                        src={image.metadata.url+'?mode=resize&width=400'}
                        alt={image.metadata.title}
                        srl_gallery_image="true" // Add this if your thumbnail is not recognized
                        style={{width: '100%'}}
                      />
                    </a>
                    <div className="meta">
                      <h3>{image.metadata.title}</h3>
                      <h4>{image.metadata.subtitle}</h4>
                    </div>
                  </div>
                </div>
              )
              }
            </SRLWrapper>
          </div>
        </div>
      </div>
    </SimpleReactLightbox>
    )
  }
}
function mapStateToProps(redux) {
  return {
    redux
  }
}
function mapDispatchToProps(dispatch){
  return bindActionCreators(ActionCreators, dispatch)
}
export default connect(mapStateToProps, mapDispatchToProps)(Gallery)