import React, { useEffect, useState } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { ActionCreators } from '../../../redux/actions'

import TextInput from '../../../components/UI/TextInput'

let feedInfoDebounce;
// reposter._id+"_channel_instrct"
const ChannelInput = (props) => {
  const [value, setValue] = useState(props.value)

  return <input 
    id={props.id+"_channel_name"}
    onFocus={()=>document.getElementById(props.id+"_channel_instrct").classList.remove('hidden')}
    onBlur={()=>document.getElementById(props.id+"_channel_instrct").classList.add('hidden')}
    type="text"
    name={props.name}
    className="channel-name"
    onChange={async ($el)=>{
      const { value } = $el.target
      setValue(value)
      const channel_name = await props.onChange(value)
      setValue(channel_name)
    }}
    value={value}
  />
}

const AllReposters = (props) => {
  const [reposters, setReposters] = useState(props.redux.reposters)
  const [currentNewFeedPanel, setCurrentNewFeedPanel] = useState(false)
  const [rssInfo, setRssInfo] = useState(false)
  const [newRSSURL, setNewRSSURL] = useState("")
  const [newRSSTitle, setNewRSSTitle] = useState("")
  const [newRSSActive, setNewRSSActive] = useState(true)
  const [newRSSpriority, setNewRSSPriority] = useState(5)
  const [newRSSPostLimit, setNewRSSPostLimit] = useState(5)  
  const [newRSSKeyword, setNewRSSkeyword] = useState("")
  const [newRSSKeywords, setNewRSSkeywords] = useState([])
  const [newRSSCategories, setNewRSSCategories] = useState([])
  const [newRssApprovalRequired, setNewRssApprovalRequired] = useState(true)
  const [isYTSearchQuery, setIsYTSearchQuery] = useState(false)
  const [rssInterval, setRssInterval] = useState(props.redux.settings.rss_interval)
  const toggleClass = (itemID, _class) => {
    var element = document.getElementById(itemID);
    element.classList.toggle(_class);
  }
  /* new feed stuff */
  const handleInputchange = (item, stateSetter, arrayToAddTo = false, isRSS = false) => {
    if(arrayToAddTo){
      const update = [...arrayToAddTo, item.value]
      return stateSetter(update)
    }

    if(isRSS){
      clearTimeout(feedInfoDebounce)
      let url = item.value
      if(url.indexOf('youtube') > -1){
        alert("youtube!")
        url = `https://www.youtube.com/feeds/videos.xml?channel_id=${url.split("channel/").pop()}`
      }
      stateSetter(url)
      feedInfoDebounce = setTimeout(()=>{
        fetch('/api/rss/info/', {
          method: "POST",
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({url})
        }).then(r=>r.json()).then(info=>{
          if(info.error) return alert(info.error)
          setRssInfo(info)
          if(newRSSTitle == "" && info.feed) setNewRSSTitle(info.feed.title)
        })
      }, 1000)
    } else {
    
      stateSetter(item.value)
    }
  }
  
  const addFeed = async (reposter_id) => {
    clearTimeout(feedInfoDebounce)
    if(!newRSSURL || (!newRSSTitle && !isYTSearchQuery)) return alert("You must enter all feed fields to add a feed (keywords not required)")

    const newFeed = {
      title: isYTSearchQuery ? "YT Search: "+newRSSURL : newRSSTitle,
      active: newRSSActive,
      priority: newRSSpriority,
      keywords: newRSSKeywords,
      categories: newRSSCategories,
      request_approval: newRssApprovalRequired,
      is_youtube_search: isYTSearchQuery,
      repost_limit: newRSSPostLimit
    }

    if(!isYTSearchQuery){
      newFeed.url = newRSSURL
    } else {
      newFeed.search_term = newRSSURL
    }
      
    let {reposter} = getReposterById(reposter_id)
    reposter.feeds.push(newFeed)
    
    await props.updateReposter(reposter)

    setNewRSSURL("")
    setNewRSSTitle("")
    setNewRSSActive(true)
    setNewRSSPriority(5)
    setNewRSSkeyword("")
    setNewRSSkeywords([])
    setNewRSSCategories([])
    setNewRssApprovalRequired(true)
    setRssInfo(false)
    setNewRSSPostLimit(5)
    setIsYTSearchQuery(false)

    document.getElementsByName('feed_url')[0].value = ""
    
    document.getElementById("add_feed_"+reposter._id).classList.remove('open')
    
    const feeds = document.getElementsByClassName("feed")
    for (let i = 0; i < feeds.length; i++) {
      const feed = feeds[i];
      feed.classList.remove('open')
      
    }

    if(!isYTSearchQuery) document.getElementsByName('feed_title')[0].value = ""
    // document.getElementsByName('feed_priority')[0].value = ""

  }

  const addNewRSSKeyword = () => {
    if(!newRSSKeyword || newRSSKeyword.trim() == "") return
    const newState = [...newRSSKeywords, newRSSKeyword.trim()]
    setNewRSSkeyword(null)
    const keywordFields = document.getElementsByName('keyword')
    for (let i = 0; i < keywordFields.length; i++) {
      const field = keywordFields[i];
      field.value = null 
      field.value = newRSSKeyword
    }
    setNewRSSkeywords(newState)
  }

  const addCategory = (category) => {
    if(newRSSCategories.indexOf(category) != -1) return
    const newCategories = [...newRSSCategories, category]
    setNewRSSCategories(newCategories)
    
    const index = rssInfo.categories.indexOf(category);
    if (index > -1) {
      let updatedRSSInfo = Object.assign({}, rssInfo)
      updatedRSSInfo.categories.splice(index, 1);
      setRssInfo(updatedRSSInfo)
    }
  }

  const refreshRSSCategories = () => {
    fetch('/api/rss/info/'+encodeURIComponent(newRSSURL)).then(r=>r.json()).then(info=>{
      newRSSCategories.map(kwd => {
        const index = info.categories.indexOf(kwd);
        if (index > -1) {
          info.categories.splice(index, 1);
        }
      }) 
      setRssInfo(info)
    })
  }

  const deleteCategory = (kwd) => {
    if(!window.confirm("Delete category: "+kwd) ) return false
    const index = newRSSCategories.indexOf(kwd);
    if (index > -1) {
      let updatedRSSCategories = [...newRSSCategories]
      updatedRSSCategories.splice(index, 1);
      setNewRSSCategories(updatedRSSCategories)
    }
  }

  const deleteKeyword = (kwd) => {
    if(!window.confirm("Delete Keyword: "+kwd) ) return false
    const index = newRSSKeywords.indexOf(kwd);
    if (index > -1) {
      let updatedRSSKewords = [...newRSSKeywords]
      updatedRSSKewords.splice(index, 1);
      setNewRSSkeywords(updatedRSSKewords)
    }
  }
  /* feed/reposter edit stuff */

  const handleCheckboxChange = (item, stateSetter, isYoutubeSearchEnabler) => {
    if(isYoutubeSearchEnabler && item.target.checked){
      setRssInfo(false)
    }
    stateSetter(item.target.checked)
  }

  const getReposterById = (_id) => {
    let rIndex
    const reposter = reposters.filter((r, i) => {
      if(_id == r._id){
        rIndex = i
        return true
      }
    })[0]
    return {reposter, rIndex}
  }

  const getFeedById = (feeds, _id) => {
    let fIndex
    const feed = feeds.filter((feed, i)=> { 
      if(feed._id == _id){
        fIndex = i
        return true
      }
    })[0]
    return {feed, fIndex}
  }
  const deleteFeed = (reposter_id, _id) => {
    let { reposter } = getReposterById(reposter_id)
    let { fIndex } = getFeedById(reposter.feeds, _id)
    reposter.feeds.splice(fIndex, 1)
    props.updateReposter(reposter)
  }
  
  const deactivateFeed = (reposter_id, _id) => {
    let { reposter } = getReposterById(reposter_id)
    let { feed } = getFeedById(reposter.feeds, _id)
    feed.active = false
    props.updateReposter(reposter)
  }

  const activateFeed = (reposter_id, _id) => {
    let {reposter} = getReposterById(reposter_id)
    let {feed} = getFeedById(reposter.feeds, _id)
    feed.active = true
    props.updateReposter(reposter)
  }

  const editFeedField = (prop, value, _id, reposter_id) => {
    let {reposter} = getReposterById(reposter_id)
    let {feed} = getFeedById(reposter.feeds, _id)
    
    feed[prop] = value
    console.log({prop, value})
    const saveButton = document.getElementById (reposter_id+"_save")
    saveButton.classList.remove('hidden')
  }

  const deleteReposter = (_id) => {
    if(window.confirm("Are you sure you want to delete this reposter?"))
      props.deleteReposter(_id)
  }

  const editReposterName = ({field, prop}) => {
    
  }

  const updateReposter =  async (_id) => {
    let { reposter } = getReposterById(_id)
    await props.updateReposter(reposter)
    const panels = await document.getElementsByClassName('panel')
    const reposter_details = await document.getElementsByClassName('reposter-details')
    const saveButton = document.getElementById (_id+"_save")
    saveButton.classList.add('hidden')
    for (let i = 0; i < panels.length; i++) {
      const panel = panels[i];
      panel.classList.remove('open')  
    }
    for (let i = 0; i < reposter_details.length; i++) {
      const r = reposter_details[i];
      r.classList.remove('open')  
    }
    
  }

  const deactiveReposter = (_id) => {
    let { reposter } = getReposterById(_id)
    reposter.active = false
    updateReposter(_id)
  }

  const activeReposter = (_id) => {
    let { reposter } = getReposterById(_id)
    reposter.active = true
    updateReposter(_id)
  }

  const toggleAddFeed = async ($el, reposter_id) => {
    await setCurrentNewFeedPanel(reposter_id)
    const isOpen = document.getElementById("add_feed_"+reposter_id).classList.value.indexOf('hidden') > -1
    const addFeedButtons = document.getElementsByClassName('toggle-add-feed')
    for (let i = 0; i < addFeedButtons.length; i++) {
      const feedButton = addFeedButtons[i];
      feedButton.innerHTML = "NEW FEED"
    }
    document.getElementById("add_feed_"+reposter_id).classList.toggle("hidden");
    $el.target.innerHTML = isOpen ? "CLOSE" : "NEW FEED"
  }

  const updateReposterChannel = async (value, reposter_id) => {
    const defaultMessage = 'Enter a channel id to update repost channel'
    
    const { reposter } = getReposterById(reposter_id)

    const feedback = document.getElementById(reposter_id+"_channel_instrct")
    
    const result = await fetch('/api/discord/channel/'+value).then(r=>r.json())
    
    if(result.error) {
      feedback.innerHTML = result.error
      return value
    }
    feedback.innerHTML = defaultMessage
    
    reposter.channel_id = value
    reposter.channel_name = result.channel_name
    document.getElementById(reposter._id+"_save").classList.remove("hidden")
    return result.channel_name
  }
  
  let debounce

  const updateReposterTitle = async (value, reposter_id) => {
    const { reposter } = getReposterById(reposter_id)
    reposter.title = value
    document.getElementById(reposter._id+"_save").classList.remove("hidden")
    return value
  }

  const handleSettingsChange = async ({value, prop}) => {
    setRssInterval(value)
    clearTimeout(debounce)
    debounce = setTimeout(async ()=>{
      let settings = await fetch('/api/settings', {
        method: 'post',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({prop, value})
      }).then(res=>res.json()).catch(e=>console.log({e}))
      props.siteSettings(settings)
      
    }, 300)
  }

  useEffect(() => {
    setReposters(props.redux.reposters)
    setRssInterval(props.redux.settings.rss_interval)
  }, [props.redux])

  return (
    <div className="reposters-wrap row">
      <div className="col-lg-12">
        <p>
          <TextInput placeholder="RSS Repost Interval (Minutes)" width="100%" type="number" stateless name="rss_interval" onChange={handleSettingsChange} />
        </p>
      </div>
    {
      reposters?.length > 0 && props.redux.reposters.map(reposter => 
        <div className="reposter fields col-lg-12" key={reposter._id}>
          <div className="preview row">
            {/* <div className="title">{reposter.title}</div> */}
            {/* <input type="hidden" value="" id={reposter._id+"_data"} /> */}
            <div className="col-md-3">
              <ChannelInput
                id={reposter._id}
                value={reposter.title}
                onChange={(value)=>updateReposterTitle(value, reposter._id)}
                />
            </div>
            <div className="col-md-3" style={{position: "relative", width: "100%"}}>
              <span id={reposter._id+"_channel_instrct"} className="hidden" style={{left: 0, top: -13, fontSize: 10, position: 'absolute'}}>Enter a channel id to update repost channel</span>
              <ChannelInput id={reposter._id} value={reposter.channel_name} onChange={(value)=>updateReposterChannel(value, reposter._id)}/>
            </div>
            <div className="col-md-3 feeds">Total Feeds: {reposter.feeds.length}</div>
            <div className="col-md-3 channel-status" style={{width: '100%', textAlign: 'left'}}>
              <span onClick={()=>reposter.active ? deactiveReposter(reposter._id) : activeReposter(reposter._id) }>{reposter.active ? "🟢 Active" : "🔴 Inactive"}</span>
              <span onClick={()=>toggleClass(reposter._id, "open")}>⬇️</span>
            </div>
            <div id={reposter._id+"_save"} className="save-edits hidden" style={{position: 'absolute', right: 5, top: 5.5, maxWidth: 62}}>
              <button className="main-button green" onClick={()=>updateReposter(reposter._id)}>SAVE</button>
            </div>
          </div>
          <div id={reposter._id} className="reposter-details">
            {
              reposter.feeds && reposter.feeds.map(feed =>
                <div className="feed" key={feed._id}>
                  <div className="header" onClick={()=>toggleClass(feed._id, "open")}>
                    <p>⬇️</p>
                    <p>Feed: <span className="title">{feed.title}</span></p>
                  </div>
                  <div className="panel" id={feed._id}>
                    <TextInput onChange={({prop, value})=>editFeedField(prop, value, feed._id, reposter._id)} stateless placeholder={feed.is_youtube_search ? "Search Term:" : "URL:"} width={"100%"} value={feed.url || feed.search_term} name={ !feed.is_youtube_search ? 'url' : 'feed.search_term'}/>
                    <div className="active">Priority: {feed.priority}</div>
                    { feed.keywords.length > 0 &&
                        <div className="active">{feed.keywords.map(kwd=><span key={kwd} className="keyword">{kwd}</span>)}</div> 
                    }
                    { feed.categories.length > 0 &&
                      <div className="active">{feed.categories.map(kwd=><span key={kwd} className="keyword">{kwd}</span>)}</div>
                    }
                    {!feed.is_youtube_search && <>
                      { feed.categories.length == 0 &&
                        <div className="active">🔴 Category Matching off</div>
                      }
                    </>}
                      { feed.keywords.length == 0 &&
                        <div className="active">🔴 Keyword Matching off</div>
                      }
                    <div className="active">{feed.active ? "🟢 Feed Active" : "🔴 FeedInactive"}</div>
                    <div className="actions">
                      <button onClick={()=> feed.active ? deactivateFeed(reposter._id, feed._id) : activateFeed(reposter._id, feed._id)} className={`main-button ${feed.active ? "red" : "green"}`} style={{marginRight: 5}}>{feed.active ? "DEACTIVATE FEED" : "ACTIVATE FEED"}</button>
                      <button className="main-button red" onClick={()=>deleteFeed(reposter._id, feed._id)}>DELETE</button>
                    </div>
                  </div>
                </div>
              )
            }
            {
              currentNewFeedPanel === reposter._id &&
                <div className="col-sm-12 hidden reposter-add-feed" id={"add_feed_"+reposter._id} style={{marginBottom: 15, marginTop: 15, paddingBottom: 15, background: 'rgba(255,255,255,.5)'}}>
                  <h2>Add Reposter Feeds</h2>

                  <input
                    type="checkbox"
                    name="activated"
                    id="yt-search-query"
                    checked={isYTSearchQuery}
                    onChange={(state) => handleCheckboxChange(state, setIsYTSearchQuery, true)} />

                  <label style={{color: '#333', marginRight: 20}} htmlFor="yt-search-query"> &nbsp; Use Youtube Search RSS</label>
                  
                  <TextInput
                    completedColor={'#52c1f7'}
                    icon="fas fa-rss"
                    color="#333"
                    width={"100%"}
                    marginBottom={15}
                    name="feed_url"
                    value={newRSSURL}
                    placeholder={isYTSearchQuery ? "Youtube Search" : "RSS URL"}
                    onChange={(newText) => handleInputchange(newText, setNewRSSURL, false, !isYTSearchQuery)}
                  />
                  { !isYTSearchQuery &&
                    <TextInput
                      completedColor={'#52c1f7'}
                      icon="fas fa-rss"
                      color="#333"
                      width={"100%"}
                      marginBottom={15}
                      name="feed_title"
                      value={newRSSTitle}
                      placeholder={rssInfo.feed ? rssInfo.feed.title : "Feed Title"}
                      onChange={(newText) => handleInputchange(newText, setNewRSSTitle)}
                    />
                  }
                  {/* <TextInput
                    completedColor={'#f00'}
                    icon="fas fa-dot-circle"
                    color="#333"
                    width={"100%"}
                    marginBottom={15}
                    name="feed_priority"
                    value={newRSSpriority}
                    type="number"
                    placeholder="Priority"
                    onChange={(newText) => handleInputchange(newText, setNewRSSPriority)}
                  /> */}
                  <TextInput
                    completedColor={'#080'}
                    icon="fas fa-sort-amount-up"
                    color="#333"
                    width={"100%"}
                    marginBottom={15}
                    name="repost_limit"
                    value={newRSSPostLimit}
                    type="number"
                    placeholder="Repost Limit (every 6 hours)"
                    onChange={(newText) => handleInputchange(newText, setNewRSSPostLimit)}
                  />
                  <div className="keywords-wrap">
                    { rssInfo.categories && <>
                      <p>Suggested (recent {rssInfo.feed.title} categories): </p>
                      <div>
                        { rssInfo.categories.map(category => <span className="category-tag" onClick={()=>addCategory(category)} key={category}>{category}</span>) }
                        <button className="main-button" onClick={ refreshRSSCategories } style={{marginTop: 15}}>RELOAD</button>
                      </div>
                      <div>
                        {newRSSCategories.map(kwd => <span className="keyword-tag" onClick={()=>deleteCategory(kwd)} key={kwd}>{kwd}</span>)}
                      </div>
                      </>
                    }
                    {newRSSKeywords.length > 0 && <>
                    <div className="keywords">
                      {newRSSKeywords.length > 0 && <p>Keywords</p>}
                      {newRSSKeywords.map(kwd => <span className="keyword-tag" onClick={()=>deleteKeyword(kwd)} key={kwd}>{kwd}</span>)}
                    </div>
                    </>}
                    <div style={{display: 'flex', alignItems: 'center'}}>
                      <TextInput
                        completedColor={'#52c1f7'}
                        icon="fas fa-key"
                        color="#333"
                        width={"calc(100% - 150px)"}
                        marginBottom={15}
                        name="keyword"
                        value={newRSSKeyword}
                        placeholder="Keywords"
                        onChange={(newText) => handleInputchange(newText, setNewRSSkeyword)}
                      />
                      <button className="main-button" style={{width: 150, height: 40}} onClick={addNewRSSKeyword}>Add</button>
                    </div>
                  </div>

                  <button className="main-button add-feed" style={{marginTop: 40, fontWeight: 800}} onClick={()=>addFeed(reposter._id)}>Add Feed</button>
                  
                  <input
                    type="checkbox"
                    name="activated"
                    id="activated"
                    checked={newRSSActive}
                    onChange={(state) => handleCheckboxChange(state, setNewRSSActive)} />

                  <label style={{color: '#333', marginRight: 20}} htmlFor="activated"> &nbsp; Activated</label>
                  
                  <input
                    type="checkbox"
                    name="request_approval"
                    id="request_approval"
                    checked={newRssApprovalRequired}
                    onChange={(state) => handleCheckboxChange(state, setNewRssApprovalRequired)} />

                  <label style={{color: '#333', marginRight: 20}} htmlFor="request_approval"> &nbsp; Approval Required</label>
                  
                </div>
            }
            <button className="main-button green toggle-add-feed" onClick={($el)=>toggleAddFeed($el, reposter._id)} style={{marginTop: 8, marginBottom: 15, background: 'transparent'}}>NEW FEED</button>
          </div>
        </div>
      )
    }
  </div>
  )
}

function mapStateToProps(redux) {
  return {
    redux
  }
}

function mapDispatchToProps(dispatch){
  return bindActionCreators(ActionCreators, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(AllReposters)
