import React, { Component } from 'react'
import { BrowserRouter as Router, NavLink as Link, Route } from 'react-router-dom'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { ActionCreators } from '../../redux/actions/index'
import SideMenu from '../SideMenu'

class HeaderNav extends Component<{}> {
  constructor(props){
    super(props)
    this.state = {
      open_menu: false
    }
  }
  _handleKeyDown = (event) => {
    switch( event.keyCode ) {
      case 27:
        this.setState({open_menu: false})
          break;
      default: 
          break;
    }
  }
  componentDidMount(){
    document.addEventListener("keydown", this._handleKeyDown);
  }
  render() {
    return (<>
      <header id="admin-header">
        {
          this.props.redux.user && <img className="avatar" src={this.props.redux.user.pfp || require('../../images/social/discord.png').default} alt={this.props.redux.user.discord.username} style={{width: 45, height: 45}}/>
        }
        <div id="nav-menu">
          { this.props.redux.user.super_admin && <>
          <Link to="/admin/"><li>Dashboard</li></Link>
          <Link to="/admin/releases"><li>Releases</li></Link>
          <Link to="/admin/egg"><li>Egg</li></Link>
          </>}
          <Link to="/admin/rss"><li>RSS</li></Link>
          { this.props.redux.user.super_admin && <>
          <Link to="/admin/gallery"><li>Gallery</li></Link>
          <Link to="/admin/bio"><li>Bio</li></Link>
          <Link to="/admin/settings"><li>Settings</li></Link>
          </>}
        </div>
       </header>

       <div id="mobile-menu">
          <div onClick={()=>{this.setState({open_menu: !this.state.open_menu})}} className="mobile-toggle"><i className="fas fa-bars"></i></div>
          <SideMenu open={this.state.open_menu}>
            <div className="mobile-toggle inside" onClick={()=>{this.setState({open_menu: !this.state.open_menu})}}>
              <i className="fas fa-times"></i>   
            </div>
            <div className="menu-items">
              { this.props.redux.user.super_admin && <>
                  <Link to="/admin/"><li onClick={()=>{this.setState({open_menu: false})}}>Dashboard</li></Link>
                  <Link to="/admin/releases"><li onClick={()=>{this.setState({open_menu: false})}}>Releases</li></Link>
                  <Link to="/admin/egg"><li onClick={()=>{this.setState({open_menu: false})}}>Egg</li></Link>
              </>}
                  <Link to="/admin/rss"><li onClick={()=>{this.setState({open_menu: false})}}>RSS</li></Link>
              { this.props.redux.user.super_admin && <>
                  <Link to="/admin/gallery"><li onClick={()=>{this.setState({open_menu: false})}}>Gallery</li></Link>
                  <Link to="/admin/bio"><li onClick={()=>{this.setState({open_menu: false})}}>Bio</li></Link>
                  <Link to="/admin/settings"><li onClick={()=>{this.setState({open_menu: false})}}>Settings</li></Link>
              </>}
            </div>
            <p>Menu things</p>
          </SideMenu>
        </div>
    </>)
  }
}

function mapStateToProps(redux) {
  return {
    redux
  }
}

function mapDispatchToProps(dispatch){
  return bindActionCreators(ActionCreators, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(HeaderNav)