import createReducer from '../lib/createReducer'
import * as types from '../actions/types'

export const secret_galleries = (state = [], action) => {
	switch(action.type) {
		case 'ECRET_GALLERIES_PENDING':
				console.log('GET_SECRET_GALLERIES_PENDING')
				return {
						isLoading: true
				}
				break;
		case 'SECRET_GALLERIES_REJECTED':
			console.log('GET_SECRET_GALLERIES_REJECTED')
				return false
				break;
		case 'SECRET_GALLERIES_FULFILLED':
			console.log('GET_SECRET_GALLERIES_FULFILLED')
			let newState = action.payload.data
			return newState;
		
		// Delete Gallery
		case 'DELETE_GALLERY_PENDING':
				console.log('DELETE_GALLERY_PENDING')
				return {
						isLoading: true
				}
				break;

		case 'DELETE_GALLERY_REJECTED':
				console.log('DELETE_GALLERY_REJECTED')
				return false
				break;

		case 'DELETE_GALLERY_FULFILLED':
			console.log('DELETE_GALLERY_FULFILLED', action.payload.data)
			let newerState = action.payload.data
			return [];

		default:
				return state
	}
}
