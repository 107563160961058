import React, { Component } from 'react'
import './css/fileupload.css'

class FileUpload extends Component<{}> {
  constructor(props){
    super(props)
  
    this.state = {
      uploaded: false
    }
  }
  componentDidMount(){
    let dropArea = document.getElementById('drag-and-drop-files'+this.props.id)
    ;['dragenter', 'dragover', 'dragleave', 'drop'].forEach(eventName => {
      dropArea.addEventListener(eventName, this.preventDefaults, false)
    })

    ;['dragenter', 'dragover'].forEach(eventName => {
      dropArea.addEventListener(eventName, highlight, false)
    })
    
    ;['dragleave', 'drop'].forEach(eventName => {
      dropArea.addEventListener(eventName, unhighlight, false)
    })
    dropArea.addEventListener('drop', this.handleDrop, false)
    
    function highlight(e) {
      dropArea.classList.add('highlight')
    }
    
    function unhighlight(e) {
      dropArea.classList.remove('highlight')
    }
  }
  
  preventDefaults = (e) => {
    e.preventDefault()
    e.stopPropagation()
  }

  handleDrop = (e) => {
    let dropArea = document.getElementById('drag-and-drop-files'+this.props.id)
    dropArea.classList.add('added')
    let dt = e.dataTransfer
    let files = dt.files

    this.handleFiles(files, true)
  }

  handleFiles = (file, drop) => {
    if(!drop && !file.target.files[0]) return false
    let fileType = file.type || file[0].type
    let passList = {
      audio: ["audio/wav", "audio/mp3", "audio/aiff", "audio/mpeg"],
      image: ["image/png", "image/gif", "image/jpeg"],
      video: ["image/png", "image/gif", "image/jpeg"]
    }
    if(passList[this.props.type].indexOf(fileType) > -1 || !drop){


      if(this.props.type === 'image'){
        this.previewFile(file[0] || file)
      }
      
      this.props.fileData((this.props.type || 'file'), !drop ? file.target.files[0] : file, drop)
      
      this.setState({
        uploaded: this.props.noPreview ? false : true,
        filename: !drop ? file.target.files[0].name : file && file[0] ? file[0].name : file.name
      })

    } else {

      alert('Invalid File Type')
      console.log(passList[this.props.type], fileType, {type: file})

    }

  }

  previewFile(file) {
    if(file.target && file.target.files[0]){
      file = file.target.files[0]
    }
    let dropArea = document.getElementById('drag-and-drop-files'+this.props.id)
    if(!this.props.noPreview){
      let reader = new FileReader()
      reader.readAsDataURL(file)
      reader.onloadend = function() {
        dropArea.style.background = 'url('+reader.result+')'
        dropArea.style.backgroundSize = 'cover'
      }
    }
  }

  render() {
    return (
      <div id={"drag-and-drop-files"+this.props.id} style={this.props.style}>
        <div id="drop-area">
          <form className="my-form">
            {(this.props.type === 'video' && !this.state.uploaded) &&
              <label htmlFor={"fileElem"+this.props.id}>
                <i className="fas fa-file-video song-upload-button" style={{color: '#fff'}}></i>
                <br/><br/>
              </label>}
            {!this.state.uploaded &&  
              <p>{this.props.uploadText || (this.props.type === 'image' ?
                this.props.uploadMessage || 'Upload your Artwork in JPEG, GIF, or PNG formats'
                :
                this.props.type === 'audio' ?
                this.props.uploadMessage || 'Upload Song file in MP3, Wav, or AIFF format.'
                  :
                  this.props.uploadMessage || 'Upload Video file in MPEG, MP4, or AVI format.'
                )
              }</p>
            }
            {this.props.type === 'image' ?
              <input type="file" id={"fileElem"+this.props.id} accept="image/gif, image/png, image/jpeg" onChange={this.handleFiles} />
              :
              this.props.type === 'audio' ?
                <input type="file" id={"fileElem"+this.props.id} accept="audio/mp3, audio/aiff, image/wav"  onChange={this.handleFiles} />
                :
                <input type="file" id={"fileElem"+this.props.id} accept="video/mpeg, video/mp4, video/avi"  onChange={this.handleFiles} />
            }
            
            {this.state.uploaded ?  
              <label htmlFor={"fileElem"+this.props.id}>{(this.props.type === 'audio' || this.props.type === 'video') && <i className="fas fa-check-double song-upload-button" style={{color: '#3fa96a'} }></i>}</label>
              :
              <label htmlFor={"fileElem"+this.props.id}>{
                this.props.type === 'audio' ?
                  <i className="fas fa-cloud-upload-alt song-upload-button" style={{color: '#fff'} }></i>
                    :
                    this.props.type === 'image' ?
                      <i className="fas fa-images song-upload-button" style={{color: '#fff'}}></i>
                        :
                      ''}</label>
                      
            }
          {this.state.filename &&  
            <p>{(this.props.type === 'audio' || this.props.type === 'video') && this.state.filename}</p>
          }
          </form>
        </div>
      </div>
    )
  }
}

export default FileUpload