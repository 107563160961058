import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { ActionCreators } from '../../redux/actions/index'
import ReactQuill, { Quill, Mixin, Toolbar } from 'react-quill';
import 'react-quill/dist/quill.snow.css'; // ES6

class Bio extends Component<{}> {
  constructor(props) {
    super(props)
    this.state = { 
      bio: this.props.redux.settings.bio || ''
    }
  }

  handleChange = async (value) => {
    clearTimeout(this.debounce)
    this.setState({bio: value})
    this.debounce = setTimeout(async ()=>{
      let settings = await fetch('/api/settings', {
        method: 'post',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({prop: 'bio', value})
      }).then(res=>res.json())
      this.props.siteSettings(settings)
    }, 300)
  }
  componentWillReceiveProps(nuProps){
    if( !Object.is(JSON.stringify(this.props.redux.settings.bio), JSON.stringify(nuProps.redux.settings.bio)) ){
      this.setState({bio: nuProps.redux.settings.bio})
    }
  }
  render() {
    return (
      <div className="container" id="bio-page" style={{maxWidth: 900}}>
        <h1>Edit Bio</h1>
        <ReactQuill
          theme="snow"
          value={this.state.bio}
          onChange={this.handleChange}
        />
      </div>
    )
  }
}

function mapStateToProps({settings}) {
  return {
    redux: {settings}
  }
}

function mapDispatchToProps(dispatch){
  return bindActionCreators(ActionCreators, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(Bio)