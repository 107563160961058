import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { ActionCreators } from '../redux/actions/index'

class LinkTree extends Component<{}> {
  constructor(props){
    super(props)

    this.state = {

    }
  }
  async componentDidMount(){
  }
  render() {
    return (
      <div className="main" style={{minHeight: '100vh', backgroundPosition: 'center center', backgroundImage: `url( ${require('../images/links_bg-green.png').default} )`, backgroundSize: 'cover', backgroundAttachment: 'fixed', paddingBottom: 64}}>
        <div className="container center" style={{paddingTop: 60}}>
          <div style={{background: 'rgba(0,0,0,.0)', maxWidth: 300, margin: 'auto', paddingBottom: 25}}>
            <img src={require('../images/hero-lips-green.gif').default} alt="Oh shut it" style={{maxWidth: 240, marginBottom:32}} /><br />
            <img src={require('../images/logo-masked-green.png').default} alt="Oh shut it" style={{maxWidth: 240}} />
          </div>
          <div className="all-links" style={{paddingTop: 60}}>

          { this.props.redux.settings.use_featured &&<>
            <div className="featured-home center" style={{margin: '0 auto'}}>
              {this.props.redux.settings.featured_iframe && <div dangerouslySetInnerHTML={{__html:this.props.redux.settings.featured_iframe}} />}
            </div>
          </>
          }
          {
            this.props.redux.settings && this.props.redux.settings.links && this.props.redux.settings.links.map((link, index) => 
              <a key={index} target="_blank" href={link.url} className="link-tag">
                <div className="link-wrapper">
                  <h3>{link.title}</h3>
                </div>
              </a>
            )
          }
          </div>
        </div>
      </div>
    )
  }
}
function mapStateToProps(redux) {
  return {
    redux
  }
}
function mapDispatchToProps(dispatch){
  return bindActionCreators(ActionCreators, dispatch)
}
export default connect(mapStateToProps, mapDispatchToProps)(LinkTree)