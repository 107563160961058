import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import axios from 'axios'

import { ActionCreators } from '../../redux/actions/index'
import Button from '../UI/Button'
import Switch from '../UI/Switch'
import NewReleasePopup from './NewReleasePopup'

import './css/releases.css'
class Releases extends Component<{}> {
  constructor(props){
    super(props)
  
    this.state = {
      create_new_release: false,
      releases: this.props.redux.all_songs || [],
      show_upcoming: this.props.redux.settings.show_upcoming,
    }
    this.baseState = this.state
  }
  handleInputchange = ({value, prop}) => {
    this.setState({[prop]: value})
  }
  toggleNewReleasePopup = () => {
    console.log('TOGGLING')
    if(this.state.create_new_release){
      console.log('TOGGLING FALSE')
      this.setState({create_new_release: false})
    } else {
      console.log('TOGGLING TRUE')
      this.setState({create_new_release: true})
    }
  }
  publishRelease = async (form) => {
    axios.request( {
      method: 'post', 
      url: '/api/release', 
      data: form, 
      onUploadProgress: (p) => {
        console.log(p); 
        this.setState({
          fileprogress: p.loaded / p.total
        })
      }
    }).then (uploaded => {
      this.setState({
        fileprogress: 1.0,
      })
      if(uploaded){
        this.setState(this.baseState, ()=>this.props.addNewSong(uploaded.data))
      }
    }).catch(error=>console.error({error_from: 'publishRelease()', error}))
  }
  isReleased(GivenDate){
    var CurrentDate = new Date();
    GivenDate = new Date(GivenDate);

    if(GivenDate > CurrentDate){
        return false
      } else {
        return true
    }
  }
  componentWillReceiveProps(nuProps){
    if( !Object.is(JSON.stringify(this.props.redux.all_songs), JSON.stringify(nuProps.redux.all_songs)) ){
      this.setState({releases: nuProps.redux.all_songs})
    }
    if( !this.props.redux.settings.show_upcoming && 
        !Object.is(
          JSON.stringify(this.props.redux.settings.show_upcoming),
          JSON.stringify(nuProps.redux.settings.show_upcoming)
        ))
    {
      this.setState({show_upcoming: nuProps.redux.settings.show_upcoming})
    }
  }
  updateSettings = async ({value, prop}) => {
    console.log({value, prop})
    clearTimeout(this.debounce)
    this.setState({[prop]: value})
    this.debounce = setTimeout(async ()=>{
      let settings = await fetch('/api/settings', {
        method: 'post',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({prop, value})
      }).then(res=>res.json())
      this.props.siteSettings(settings)
    }, 300)
  }
  render() {
    return (
      <div className="container" id="releases-page" style={{maxWidth: 900}}>
        <div className="row">
          <div className="col-sm-12">
            <h1>Releases</h1>
            <Button onClick={this.toggleNewReleasePopup} text="SCHEDULE NEW" />
            <Switch value={this.state.show_upcoming} color="#000" style={{marginTop: 20}} onSwitch={this.updateSettings} name={"show_upcoming"} text="DISPLAY UPCOMING RELEASES ON HOMEPAGE WHEN LOGGED IN" />
            <NewReleasePopup uploadProgress={this.state.fileprogress} {...this.state} publishRelease={this.publishRelease} handleInputchange={this.handleInputchange} togglePopup={this.toggleNewReleasePopup} />
            <h2>All Releases</h2>
          </div>
        </div>
        <div id="releases-wrap" className="row">
          {
            this.state.releases.length > 0 ? this.state.releases.map((release, index) => {
              const { image, title, spotify, soundcloud, downloadable } = release
              return (
                <div className="release-item col-sm-3" key={index}>
                  <picture className="">
                    <source srcSet={`${image.metadata.url}?mode=resize-webp&width=600&height=600`} type="image/webp" className="song-image" alt={title}/>
                    <source srcSet={`${image.metadata.url}?mode=resize&width=600&height=600`} type="image/jpeg" className="song-image" alt={title}/> 
                    <img src={`${image.metadata.url}?mode=resize&width=600&height=600`} className="" alt={title}/>
                  </picture>
                  <div className="release-meta">
                    { soundcloud ?
                      <a href={soundcloud} target="_blank">
                        <i className="fab fa-soundcloud" style={{color: '#ff6600'}}></i>
                      </a>
                      :
                      <i className="fab fa-soundcloud" style={{color: '#333'}}></i>
                    }
                    { spotify ?
                      <a href={spotify} target="_blank">
                        <i className="fab fa-spotify" style={{color: '#1db954'}}></i>
                      </a>
                      :
                      <i className="fab fa-spotify" style={{color: '#333'}}></i>
                    }
                    { downloadable &&
                      <i className="fas fa-cloud-download-alt"></i>
                    }
                  </div>
                  <p className="release-title">{release.artist} - {release.title}</p>
                  <p className={this.isReleased(release.release_date) ? "release-date released" : "release-date upcoming"}>Release Date:< br/>{new Date(release.release_date).toDateString()}</p>
                </div>
              )
            }) : null
          }
        </div>
      </div>
    )
  }
}

function mapStateToProps({all_songs, settings}) {
  return {
    redux: {all_songs, settings}
  }
}

function mapDispatchToProps(dispatch){
  return bindActionCreators(ActionCreators, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(Releases)