import createReducer from '../lib/createReducer'
import * as types from '../actions/types'
import { getUserImageURL } from '../../lib/discord'

export const user = createReducer({unauthorized: 'unknown', login: false},{
	[types.USER_DATA](state, action){
		let user = action.payload
		
		if(user.discord && user.discord.avatar)
			user.pfp = getUserImageURL(user.discord.id, user.discord.avatar)

		return user
	}
})