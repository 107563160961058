import * as types from './types'

export function currentSong( userData ) {
	return {
		type: types.CURRENT_SONG,
		payload: userData
	}
}

export function loadAllSongs( songs ) {
	return {
		type: types.LOAD_ALL_SONGS,
		payload: songs
	}
}

export function addNewSong( song ) {
	return {
		type: types.LOAD_ALL_SONGS,
		add: true,
		payload: song
	}
}

export function isPaused( truth ) {
	return {
		type: types.IS_PAUSED,
		payload: truth
	}
}

export function setCurrentTime( time ) {
	
	let payload

	let hours = Math.floor(time / 3600);
	hours = hours < 10 ? '0'+hours : hours
	time %= 3600;
	let minutes = Math.floor(time / 60);

	minutes = minutes < 10 && hours > 0 ? '0'+minutes : minutes
	let seconds = time % 60;
	seconds = seconds < 10 ? '0'+seconds : seconds
	if(hours > 0){
		payload = `${hours}:${minutes}:${seconds}`
	} else {
		payload = `${minutes}:${seconds}`
	}

	return {
		type: types.CURRENT_TIME,
		payload
	}
}
