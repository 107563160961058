import React, { Component } from 'react'
import './css/button.scss'

class Button extends Component<{}> {
  constructor(props){
    super(props)
  
    this.state = {
    }
    this.handleHover = false
  }
  
  render() {
    const hoverBackgroundObject = {
      color: this.props.textHoverColor,
      background: this.props.backgroundHoverColor,
      borderColor: this.props.color,
      ...this.props.style
    }
    const backgroundObject = { 
      background: this.props.background,
      color: this.props.color,
      borderColor: this.props.color,
      ...this.props.style
    }
    return (
      <button
        onMouseOver={() => { 
            if(this.props.backgroundHoverColor) {
              this.setState({
                hover: true
              })
            }
          }
        }
        disabled={this.props.disabled}
        onMouseLeave={() => { 
            if(this.props.backgroundHoverColor) {
              this.setState({
                hover: false
              })
            }
          }
        }
        style={
          this.state.hover && this.props.backgroundHoverColor ? 
            {...hoverBackgroundObject, ...this.props.style}
          :
            {...backgroundObject, ...this.props.style}
        }
        onClick={this.props.onClick}
        className={this.props.className ? ["main-button", this.props.className].join(' ') : "main-button"}>
        { this.props.loading ? 
            <i className="fas fa-spinner spinning-animation" style={{marginRight: 5}}></i> 
          :
            this.props.text
        }
        { this.props.loading && (this.props.loadingText || " Loading...") }
      </button>
    )
  }
}

export default Button