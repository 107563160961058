import axios from 'axios'

export function getReposters() {
	return {
		type: 'GET_REPOSTERS',
		payload: axios.get('/api/reposters')
	}
}

export function deleteReposter() {
	return {
		type: 'DELETE_REPOSTER',
		payload: axios.delete('/api/reposter')
	}
}

export function addNewReposter( reposter ){
	return {
		type: 'ADD_REPOSTER',
		payload: axios.post('/api/reposter', reposter)
	}
}

export function updateReposter( reposter ){
	return {
		type: 'UPDATE_REPOSTER',
		payload: axios.put('/api/reposter', reposter)
	}
}