import * as types from './types'
import axios from 'axios'


export function getGalleries() {
	console.log('RUNNING SECRET GALLERIES REDUX ACTION')
	return {
		type: 'SECRET_GALLERIES',
		payload: axios.get('/api/galleries')
	}
}


export function deleteGallery(_id) {
	console.log('RUNNING SECRET GALLERIES REDUX ACTION')
	return {
		type: 'DELETE_GALLERY',
		payload: axios.delete('/api/gallery/'+_id)
	}
}