import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { ActionCreators } from '../redux/actions/index'

class PlaylistItem extends Component<{}> {
  constructor(props){
    super(props)
  
    this.state = {
      load: false,
      loading: false,
    }
    this.stamp = new Date()
  }

  handleLoadProgess = (time) => {
    var t1 = this.stamp
    var t2 = new Date();
    var dif = t1.getTime() - t2.getTime();

    var Seconds_from_T1_to_T2 = dif / 1000;
    var Seconds_Between_Dates = Math.abs(Seconds_from_T1_to_T2);
    if(Seconds_Between_Dates > 1){
      let currentTime = time.currentPosition / 1000
      this.props.setCurrentTime(Math.trunc(currentTime))
      this.stamp = new Date()
    }
  }
  
  togglePlay = async (e) => {
    let loadAsNewSong = this.props.redux.current_song._id !== this.props.release._id
    if(loadAsNewSong){
      await this.props.currentSong(this.props.release)
    }
    if((this.props.redux.is_paused && !loadAsNewSong) || loadAsNewSong){
      await this.props.isPaused(false)
    } else {
      await this.props.isPaused(true)
    }
  }

  render() {
    const { 
      image,
      audio,
      title,
      artist,
      spotify,
      // spotify_uri,
      // spotify_embed,
      soundcloud_embed,
      soundcloud,
      // download,
      _id
    } = this.props.release
    return (
      <div className={ this.state.load ? "playlist-item-wrap iframe-loaded aos-init aos-animate" : "playlist-item-wrap"} key={this.props.itemIndex} data-aos="fade" data-aos-duration="1000" onClick={ this.togglePlay }>
        <div className="playlist-item">
          <div className="col-sm-5">
            <p className="song-number" style={{display: 'inline-block', marginRight: 20}}>
              {this.props.itemIndex+1}
            </p>
            <div className={"song-image-wrap"} onClick={ (this.state.load || window.innerWidth < 768) ? this.togglePlay : this.firstPlay }>

            <picture className="song-image">
              <source srcSet={`${image.metadata.url}?mode=resize-webp&width=200&height=200`} type="image/webp" className="song-image" alt={title}/>
              <source srcSet={`${image.metadata.url}?mode=resize&width=200&height=200`} type="image/jpeg" className="song-image" alt={title}/> 
              <img src={`${image.metadata.url}?mode=resize&width=200&height=200`} className="song-image" alt={title}/>
            </picture>
              {
                this.props.redux.current_song && this.props.redux.current_song._id === _id ?
                <i
                  className={
                      this.props.redux.is_paused ? "fas fa-play-circle"
                      :
                        "fas fa-pause-circle"
                }></i> :
                <i className={this.state.loading ? "spinning fas fa-spinner" : "fas fa-play-circle"}></i>
              }
            </div>
            <span className="mobile">{artist} -&nbsp;</span>{title}
          </div>
          <div className="col-sm-2 desktop">
            {artist}
          </div>
          <div className="outbound-song-buttons" style={{justifyContent: 'flex-end'}}>
            <a href={spotify} target="_blank" rel="noopener noreferrer"><button className="spotify-button">SPOTIFY</button></a>
            <a href={soundcloud} target="_blank" rel="noopener noreferrer"><button className="soundcloud-button">SOUNDCLOUD</button></a>
          </div>
        </div>
      </div>
    )
  }
}

function mapStateToProps(redux) {
  return {
    redux
  }
}

function mapDispatchToProps(dispatch){
  return bindActionCreators(ActionCreators, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(PlaylistItem)